import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { Typography } from "../typrography/Typography";
import "react-circular-progressbar/dist/styles.css";

export interface CircleProgressBarProps {
  name: string;
  pct: number;
}

const CircleProgressBar = ({ name, pct }: CircleProgressBarProps) => {
  return (
    <div
      style={{
        width: (window.innerHeight / 100) * 23,
        height: (window.innerHeight / 100) * 23,
      }}
    >
      <CircularProgressbarWithChildren
        value={pct}
        strokeWidth={15}
        styles={buildStyles({
          rotation: 0.25,
          strokeLinecap: "round",
          pathTransitionDuration: 0.5,
          pathColor: "#00916E",
          textColor: "#f88",
        })}
      >
        <article>
          <Typography display="flex">{name}</Typography>
        </article>

        <article>
          <Typography display="flex" variant="xs" weight="bold">
            {Math.round(pct)}%
          </Typography>
        </article>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircleProgressBar;
