import { FiArchive, FiEdit, FiSave } from "react-icons/fi";
import { IoMdDownload, IoMdTrash } from "react-icons/io";
import styled from "styled-components";
import { TableProps } from "./TokenGenerationTable";
import { BiSolidArchiveIn } from "react-icons/bi";
import { ReactComponent as ViewDevices } from "../../assets/view-devices-icon.svg";
import { ReactComponent as Retrain } from "../../assets/retrain-icon.svg";
import { HiOutlineDuplicate } from "react-icons/hi";
import { FaPowerOff, FaSyncAlt } from "react-icons/fa";

export const StyledTable = styled.table<
  Pick<TableProps, "width" | "trBorder" | "tdColor">
>`
  width: ${({ width }) => (width ? width : "100%")};
  border-collapse: collapse;

  & th {
    border-bottom: ${(props) => props.theme.border["primary-md"]};
    font-size: ${(props) => props.theme.fontSizes["2xl"]};
    font-weight: 900;
  }

  & td {
    font-size: ${(props) => props.theme.fontSizes["lg"]};
    color: ${({ tdColor }) => (tdColor ? tdColor : "white")};
  }

  & td,
  th {
    text-align: left;
    padding: 8px;
  }

  & tr {
    ${({ trBorder }) => (trBorder ? `border-bottom: ${trBorder}` : "")}
  }
`;

export const StyledFiSave = styled(FiSave)`
  font-size: ${(props) => props.theme.fontSizes["sm"]};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;
export const StyledFiEdit = styled(FiEdit)`
  font-size: ${(props) =>
    props.size ? props.size : props.theme.fontSizes["sm"]};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledFiArchive = styled(BiSolidArchiveIn)`
  height: 1em;
  width: 1em;
  vertical-align: -0.2em;
  font-size: ${(props) =>
    props.size ? props.size : props.theme.fontSizes["sm"]};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledFaPowerOff = styled(FaPowerOff)`
  height: 1em;
  width: 1em;
  vertical-align: -0.2em;
  font-size: ${(props) =>
    props.size ? props.size : props.theme.fontSizes["sm"]};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledIoMdDownload = styled(IoMdDownload)`
  font-size: ${(props) => props.theme.fontSizes["md"]};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledHiOutlineDuplicate = styled(HiOutlineDuplicate)`
  font-size: ${(props) => props.theme.fontSizes["md"]};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledViewDevicesIcon = styled(ViewDevices)`
  width: 15px;
  height: 15px;
  &:hover {
    & path {
      fill: ${(props) => props.theme.colors["primary"]};
    }
    cursor: pointer;
  }
`;

export const StyledRetrainIcon = styled(Retrain)`
  width: 15px;
  height: 15px;
  &:hover {
    & path {
      // commenting this out for now because functions are not working
      //fill: ${(props) => props.theme.colors["primary"]};
      fill: ${(props) => props.theme.colors["grey-500"]};
    }
    cursor: not-allowed;
  }
`;

export const StyledIoMdTrash = styled(IoMdTrash)`
  font-size: ${(props) =>
    props.size ? props.size : props.theme.fontSizes["md"]};
  cursor: pointer;
  &:hover {
    color: #d64550;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
`;

export const StyledBgIoMdTrash = styled(IoMdTrash)`
  background: #d64550;
  font-size: ${(props) => props.theme.fontSizes["2xl"]};
  padding: 2px;
  color: white;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

export const StyledFaSync = styled(FaSyncAlt)`
  font-size: ${(props) =>
    props.size ? props.size : props.theme.fontSizes["sm"]};
  cursor: pointer;
  padding: 8px;
  border-radius: ${(props) => props.theme.borderRadius["rounded-lg"]};
  color: ${(props) => props.theme.colors["black-200"]};
  background-color: ${(props) => props.theme.colors["primary"]};
`;
