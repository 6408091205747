import styled from "styled-components";
import { DefaultThemeColor } from "../../app/Theme";

export const GraphTimelineButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin: 1.813rem 1.813rem 0;
  padding: 0 0.2rem 0;
`;

export const GraphTimelineButtonLabel = styled.label<{
  selected: boolean;
}>`
  padding: 10px 20px;
  border: ${({ theme }) => theme.border["primary-md"]};
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  user-select: none;
  ${({ selected, theme }) =>
    selected &&
    `
    background-color: ${theme.colors.primary};
    color: ${DefaultThemeColor["black-100"]};
  `}
`;

export const HiddenRadioButton = styled.input`
  display: none;
`;
