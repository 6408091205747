import axios, { AxiosError } from "axios";
import { ResponseObject } from "../../interfaces/response/Response";
import authService from "../authentication/auth.service";
import {
  User,
  UserCompany,
  UserInfo,
} from "../../interfaces/authentication/User";
import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { CompanyTheme } from "../../interfaces/company/Company";
import {
  validateErrorResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";
export interface CompanyCreation {
  CompanyOwnerId: string;
  Name: string;
  CompanyEmail: string;
  PhoneNumber: string;
  Address: string;
  Country: string;
  City: string;
  ProvinceState: string;
  PostalCode: string;
}

export interface Company {
  UID: string;
  Name: string;
  CompanyOwnerId: string;
  CompanyEmail: string;
  IntegratedWith: string;
  DeviceDataUpdateThreshold: number | "";
}

export interface CompanyReturnPayload {
  Company: Company;
  CompanyTheme: CompanyTheme;
  Role: string;
  CompanyToken: string;
  UserInfo: UserInfo;
}

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getCompanyUsers = createAsyncThunk(
  "user/getCompanyUsers",
  async (thunkapi) => {
    try {
      const response = await axios
        .get(`${API_URL}/UserCompany/users`)
        .then(async (response) => {
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const getCompanyTheme = createAsyncThunk(
  "company/getCompanyTheme",
  async (thunkapi) => {
    try {
      const response = await axios
        .get(`${API_URL}/CompanyTheme`)
        .then(async (response) => {
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

const registerCompany = async (company: CompanyCreation) => {
  try {
    let response: ResponseObject<string> = await axios
      .post(`${API_URL}/Company/register`, company)
      .then(async (response) => {
        if (!response.data.IsError) {
          authService.setCompanyTokenToHeader(response.data.Result);
          return response.data;
        }
      });

    return response;
  } catch (error: any) {
    return error.response.data;
  }
};

const inviteUser = async (payload: any) => {
  try {
    let response: ResponseObject<string> = await axios
      .post(`${API_URL}/Company/invite-user`, payload)
      .then(async (response) => {
        if (!response.data.IsError) {
          return response.data;
        }
      });

    return response;
  } catch (error: any) {
    return error.response.data;
  }
};

const verifyInvitation = async (token: any) => {
  try {
    let response: ResponseObject<string> = await axios
      .post(`${API_URL}/Company/verify-invitation?token=${token}`)
      .then(async (response) => {
        if (!response.data.IsError) {
          return response.data;
        }
      });

    return response;
  } catch (error: any) {
    return error.response.data;
  }
};

const updateUserRole = async (payload: UserCompany) => {
  try {
    let response: ResponseObject<string> = await axios
      .patch(`${API_URL}/Company/user-role`, payload)
      .then(async (response) => {
        if (!response.data.IsError) {
          return response.data;
        }
      });

    return response;
  } catch (error: any) {
    return error.response.data;
  }
};

const removeUserFromCompany = async (id: number) => {
  try {
    let response: ResponseObject<string> = await axios
      .delete(`${API_URL}/Company/remove-user?id=${id}`)
      .then(async (response) => {
        if (!response.data.IsError) {
          return response.data;
        }
      });

    return response;
  } catch (error: any) {
    return error.response.data;
  }
};

const updateColorTheme = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  themeColor: string,
) => {
  let encodedColor = encodeURIComponent(themeColor);
  const response: ResponseObject<CompanyTheme> = await axios
    .patch(
      `${API_URL}/CompanyTheme/updateColorTheme?updatedColorTheme=${encodedColor}`,
    )
    .then(async (response) => {
      if (!response.data.IsError) {
        await validateSuccessResult(
          response,
          dispatch,
          "Theme settings updated",
        );
        return response.data;
      }
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const resetTheme = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response: ResponseObject<CompanyTheme> = await axios
    .patch(`${API_URL}/CompanyTheme/resetToDefault`)
    .then(async (response) => {
      if (!response.data.IsError) {
        await validateSuccessResult(response, dispatch, "Theme settings reset");
        return response.data;
      }
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const updateCompanyLogo = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  mediaId: number,
) => {
  const response: ResponseObject<CompanyTheme> = await axios
    .patch(
      `${API_URL}/CompanyTheme/updateCompanyLogoMedia?updatedMediaId=${mediaId}`,
    )
    .then(async (response) => {
      if (!response.data.IsError) {
        await validateSuccessResult(
          response,
          dispatch,
          "Company profile updated",
        );
        return response.data;
      }
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

export const companyService = {
  registerCompany,
  inviteUser,
  verifyInvitation,
  updateUserRole,
  removeUserFromCompany,
  updateColorTheme,
  resetTheme,
  updateCompanyLogo,
};
