import React, { Fragment, useEffect, useState } from "react";
import {
  ButtonGroup,
  DropdownSection,
  DropdownWrapper,
  FilterOptionContainer,
  SortBySection,
  StatusAndSortContainer,
  VerticalLine,
} from "./FilterOptions.style";
import { SubTitle } from "../title/Titles.styles";
import SortBy from "../sort-by/SortBy";
import ThumbnailSize from "../thumbnail-size/ThumbnailSize";
import { ThumbnailSizeItem } from "../thumbnail-size/thumbnailSizeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import {
  ExportParamsForCaptures,
  FetchParamsForCaptures,
  FilterParam,
  resetSearchParams,
  setCaptureParameters,
  updateCaptureParameter,
} from "../../store/slices/fetchParameters/fetchParametersSlice";
import {
  exportCaptureData,
  getAllCaptures,
  searchCaptures,
} from "../../services/captures/capture.service";
import {
  resetSortByState,
  setSortBysForCaptures,
} from "../sort-by/sortBySlice";
import { useParams } from "react-router-dom";
import { Button } from "../button/Button";
import { CustomDropdown } from "../form-field/drop-down/CustomDropdown";
import {
  DropdownItem,
  getAIConfidenceDropdown,
  getAIModelsDropdown,
  getDevicesDropdown,
  getLocationsDropdown,
} from "../../store/slices/dropdownOptions/dropdownListSlice";
import {
  FilterDropdown,
  FilterOption,
} from "../dropdown/filter-dropdown/FilterDropdown";
import { ScaledButtonWrapper } from "../modals/CustomModal.style";
import { generateFileDateTimezoneName } from "../../utils/dateUtils";

interface CapturesFilterOptionsProps {
  isArchivedData?: boolean;
  thumbnailSizeItems: ThumbnailSizeItem[];
  handleChangeThumbnailViewSize: (id: number) => void;
}

const CapturesFilterOptions: React.FC<CapturesFilterOptionsProps> = ({
  isArchivedData,
  thumbnailSizeItems,
  handleChangeThumbnailViewSize,
}) => {
  const [isUpdatedParams, setIsUpdatedParams] = useState<boolean>(false);
  const { activeTab } = useParams<any>();
  const dispatch = useAppDispatch();
  const locationsDropdown = useAppSelector(getLocationsDropdown);
  const devicesDropdown = useAppSelector(getDevicesDropdown);
  const aIModelsDropdown = useAppSelector(getAIModelsDropdown);
  const aIConfidenceDropdown = useAppSelector(getAIConfidenceDropdown);
  const sortBys = useAppSelector((state: RootState) => state.sortBy);
  const searchParams = useAppSelector(
    (state: RootState) => state.fetchParams.CaptureSearchParams,
  );
  const selectedSortByOrderOption = sortBys.find((item) => {
    return (item.id === 1 || item.id === 2) && item.isActive;
  });
  const selectedSortByTypeOption = sortBys.find((item) => {
    return (item.id === 3 || item.id === 4) && item.isActive;
  });
  const [keyToResetDropdown, setKeyToResetDropdown] = useState<number>(0); // to reset dropdown selection on clear
  // State to track if data export is in progress
  const [isDataExporting, setIsDataExporting] = useState<boolean>(false);
  const onSortByChange = (sortByItem: any) => {
    const updatedParams: FetchParamsForCaptures = {
      ...searchParams,
      ascending: sortByItem.name === "ascending",
      mediaType: activeTab == "videos" ? "video" : "image",
    };
    dispatch(setCaptureParameters(updatedParams));
    filterData(updatedParams);
  };
  const filterData = (searchParams: FetchParamsForCaptures) => {
    dispatch(searchCaptures(searchParams));
  };

  const resetFilteredCaptureData = () => {
    // Reset sortby state
    dispatch(resetSortByState());
    dispatch(setSortBysForCaptures());
    dispatch(resetSearchParams({ page: "Captures" }));
    //Reset the capture search data
    dispatch(
      getAllCaptures({
        mediaType: activeTab == "videos" ? "video" : "image",
        ascending: false,
        archive: activeTab == "archived" ? true : false,
      }),
    );
    setKeyToResetDropdown((prev) => prev + 1);
  };

  const handleDropdownOptionClick = (
    dropdownId: DropdownItem,
    value: number | string,
  ) => {
    switch (dropdownId) {
      case DropdownItem.Locations:
        const locationParam: FilterParam = {
          paramName: "locationId",
          paramValue: value,
        };
        dispatch(updateCaptureParameter(locationParam));
        break;
      case DropdownItem.Devices:
        const deviceParam: FilterParam = {
          paramName: "deviceId",
          paramValue: value.toString(),
        };
        dispatch(updateCaptureParameter(deviceParam));
        break;
      case DropdownItem.AIModels:
        const modelParam: FilterParam = {
          paramName: "modelId",
          paramValue: value,
        };
        dispatch(updateCaptureParameter(modelParam));
        break;
      case DropdownItem.AIConfidence:
        const aiConfidenceParam: FilterParam = {
          paramName: "highConfidence",
          paramValue: value === 1 ? true : false,
        };
        dispatch(updateCaptureParameter(aiConfidenceParam));
    }

    const ascendingParam: FilterParam = {
      paramName: "ascending",
      paramValue: selectedSortByOrderOption?.name == "ascending" ? true : false,
    };

    const archivedParam: FilterParam = {
      paramName: "archive",
      paramValue: activeTab == "archived" ? true : false,
    };

    const mediaTypeParam: FilterParam = {
      paramName: "mediaType",
      paramValue:
        activeTab == "videos" || selectedSortByTypeOption?.name == "video" ?
          "video"
        : "image",
    };

    dispatch(updateCaptureParameter(ascendingParam));
    dispatch(updateCaptureParameter(archivedParam));
    dispatch(updateCaptureParameter(mediaTypeParam));
    setIsUpdatedParams(true);
  };
  const exportData = async () => {
    // If data is currently exporting, prevent duplicate export attempts
    if (isDataExporting) return;

    setIsDataExporting(true);

    try {
      let mediaType = "image";
      // if archived check 3 and 4
      if (isArchivedData) {
        if (
          selectedSortByTypeOption?.id == 3 ||
          selectedSortByTypeOption?.id == 4
        )
          mediaType = selectedSortByTypeOption.id == 3 ? "image" : "video";
      } else {
        mediaType = activeTab == "videos" ? "video" : "image";
      }
      const capturesParams: ExportParamsForCaptures = {
        mediaType: mediaType,
        archive: isArchivedData || false,
        locationId: searchParams.locationId,
        deviceId: searchParams.deviceId,
        modelId: searchParams.modelId,
        highConfidence: searchParams.highConfidence,
      };
      // Fetch sensor data from the server
      const capturesData = await exportCaptureData(capturesParams);

      // Check if data was received
      if (!capturesData) {
        throw new Error("No data received");
      }

      // Create a Blob object from the response data
      const blob = new Blob([capturesData], { type: "text/csv" });

      // Construct a more descriptive filename with a timestamp
      const filename = `capture-data-${generateFileDateTimezoneName()}.csv`;

      // Create a temporary URL for the blob object
      const url = URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set the filename for download

      // Append the link to the body and programmatically click it to initiate download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link and revoke the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setIsDataExporting(false);
    } catch (error) {
      console.error("Error exporting sensor data:", error);
      setIsDataExporting(false);
      // Handle errors here (e.g., show error message to the user)
    }
  };

  useEffect(() => {
    if (isUpdatedParams) {
      filterData(searchParams);
      setIsUpdatedParams(false);
    }
  }, [searchParams, isUpdatedParams]);

  return (
    <>
      <FilterOptionContainer>
        <DropdownSection>
          <SubTitle style={{ marginBottom: "4px" }}>Filter Options</SubTitle>
          <DropdownWrapper>
            <Fragment key={keyToResetDropdown}>
              {locationsDropdown ?
                <FilterDropdown
                  title="Location"
                  options={locationsDropdown.options}
                  onChange={({ label, value }: FilterOption) => {
                    handleDropdownOptionClick(locationsDropdown.id, value);
                  }}
                />
              : null}
              {devicesDropdown ?
                <FilterDropdown
                  title="Device"
                  options={devicesDropdown.options}
                  onChange={({ label, value }: FilterOption) => {
                    handleDropdownOptionClick(devicesDropdown.id, value);
                  }}
                />
              : null}
              {aIModelsDropdown ?
                <FilterDropdown
                  title="Computer Vision Model"
                  options={aIModelsDropdown.options}
                  onChange={({ label, value }: FilterOption) => {
                    handleDropdownOptionClick(aIModelsDropdown.id, value);
                  }}
                />
              : null}
              {aIConfidenceDropdown ?
                <FilterDropdown
                  title="Confidence"
                  options={aIConfidenceDropdown.options}
                  onChange={({ label, value }: FilterOption) => {
                    handleDropdownOptionClick(aIConfidenceDropdown.id!, value);
                  }}
                />
              : null}
            </Fragment>
            <ButtonGroup>
              <Button
                color="primary"
                variant="filled"
                onClick={resetFilteredCaptureData}
              >
                Clear Filters
              </Button>
              <Button color="primary" variant="filled" onClick={exportData}>
                {isDataExporting ? "Exporting..." : "Export Data"}
              </Button>
            </ButtonGroup>
          </DropdownWrapper>
        </DropdownSection>
        <StatusAndSortContainer>
          {isArchivedData && (
            <>
              <SortBySection>
                <SortBy page="captures" isForCaptureTypes />
              </SortBySection>
              <VerticalLine />
            </>
          )}
          <SortBySection>
            <SortBy
              onChange={isArchivedData ? null : onSortByChange}
              page="captures"
            />
          </SortBySection>
          <VerticalLine />
          <SortBySection>
            <ThumbnailSize
              thumbnailSizeItems={thumbnailSizeItems}
              handleChangeThumbnailViewSize={handleChangeThumbnailViewSize}
            />
          </SortBySection>
        </StatusAndSortContainer>
      </FilterOptionContainer>
    </>
  );
};

export default CapturesFilterOptions;
