import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import {
  filterDevices,
  getAllDevices,
  getDevice,
} from "../../../services/device/deviceGet.service";
import { DeviceName } from "../../../interfaces/device/DeviceName";
import { patchDeviceLocation } from "../../../services/device/devicePatch.service";
import { DeviceModel } from "../../../interfaces/device/DeviceModel";
import {
  Relay,
  RelayValues,
  RelayWithValues,
} from "../../../interfaces/device/Relay";
import deviceUtilFunc from "./deviceSliceUtil";
import { Sensor } from "../../../interfaces/device/Sensor";
import { SwrmDevices } from "../../../interfaces/device/SwrmDevice";
import { defaultLocation } from "../locations/locationsSlice";
import { DeviceGroup } from "../../../interfaces/device/DeviceGroup";
import { BoardType } from "../../../interfaces/device/BoardTypes";
import { getAllBoardTypes } from "../../../services/device/deviceBoardType.service";
import { awsUtils } from "../../../utils/awsUtils";
import { syncRelays } from "../../../services/device/deviceRelays.service";
import { OpenSuccessNotification } from "../../../components/notification/Notification";
import { Routine } from "../../../interfaces/device/Routine";
import {
  deleteRoutine,
  getRoutines,
  getRoutineStepRelays,
  getRoutineSteps,
} from "../../../services/device/deviceRoutines.service";
import { Step } from "../../../interfaces/device/Step";
import { ResponseObject } from "../../../interfaces/response/Response";
import { Location } from "../../../interfaces/device/Location";
import { DeviceLocation } from "../../../interfaces/device/DeviceLocation";

//Define a type for the slice state
interface DeviceState {
  data: SwrmDevices[];
  allDevicesForModal: SwrmDevices[] | [];
  selectedDevice: SwrmDevices;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
  activeDeviceId: string;
  boardTypes: BoardType[];
}
export const defaultSelectedSwrmDevice: SwrmDevices = {
  Id: "",
  PiSerial: "123123",
  Name: "Device Name",
  UpToDate: false,
  Deployed: false,
  WifiStatus: false,
  ConnectionStatus: false,
  Enabled: false,
  LastContact: "",
  Location: defaultLocation,
  Groups: [],
  GPSLongitude: null,
  GPSLatitude: null,
  GPSElevation: null,
  TimeZone: "",
  Sensors: [],
  DeviceModel: null,
  Thumbnail: null,
  MediaId: 0,
  RelayWithValues: [],
  Routines: [],
  Steps: [],
  StepRelayIds: [],
  ChildDevices: [],
};
const initialState: DeviceState = {
  data: [],
  allDevicesForModal: [],
  selectedDevice: defaultSelectedSwrmDevice,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
  activeDeviceId: "",
  boardTypes: [],
};

export const devicesSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setAllDevices: (state, action: PayloadAction<SwrmDevices[]>) => {
      state.data = { ...action.payload };
    },
    setActiveDeviceImageCard: (state, action: PayloadAction<string>) => {
      state.activeDeviceId = action.payload;
    },
    resetDeviceImageCard: (state) => {
      state.activeDeviceId = "";
    },
    changeDevicesOrder: (state) => {
      state.allDevicesForModal = state.allDevicesForModal.reverse();
    },
    updateSensorsInDevice: (
      state: any,
      action: PayloadAction<{ sensor: Sensor; masterDeviceId: string }>,
    ) => {
      const { sensor, masterDeviceId } = action.payload;
      const updateFunc = (sensor: any) => {
        sensor.Active = action.payload.sensor.Active;
      };

      state.data.forEach((device: any) => {
        if (device.Id === sensor.DeviceId) {
          deviceUtilFunc.updateSensorData(device, sensor.Id, updateFunc);
        } else if (device.Id === masterDeviceId) {
          const childDevice = deviceUtilFunc.findChildDeviceById(
            sensor.DeviceId,
            device,
          );
          if (childDevice) {
            deviceUtilFunc.updateChildDeviceSensor(
              childDevice,
              sensor.Id,
              updateFunc,
            );
          }
        }
      });

      if (state.selectedDevice.Id === sensor.DeviceId) {
        deviceUtilFunc.updateSensorData(
          state.selectedDevice,
          sensor.Id,
          updateFunc,
        );
      } else if (state.selectedDevice.Id === masterDeviceId) {
        const childDevice = deviceUtilFunc.findChildDeviceById(
          sensor.DeviceId,
          state.selectedDevice,
        );
        if (childDevice) {
          deviceUtilFunc.updateChildDeviceSensor(
            childDevice,
            sensor.Id,
            updateFunc,
          );
        }
      }
    },
    updateRelayInDevice: (
      state: any,
      action: PayloadAction<{ relay: RelayValues; masterDeviceId: string }>,
    ) => {
      const { relay, masterDeviceId } = action.payload;
      const updateFunc = (relay: any) => {
        relay.RelayValues = [action.payload.relay];
      };

      state.data.forEach((device: any) => {
        if (device.Id === relay.Relay?.DeviceId) {
          deviceUtilFunc.updateRelayData(device, relay.RelayId, updateFunc);
        } else if (device.Id === masterDeviceId) {
          const childDevice = deviceUtilFunc.findChildDeviceById(
            relay.Relay?.DeviceId || "",
            device,
          );
          if (childDevice) {
            deviceUtilFunc.updateChildDeviceRelay(
              childDevice,
              relay.RelayId,
              updateFunc,
            );
          }
        }
      });

      if (state.selectedDevice.Id === relay.Relay?.DeviceId) {
        deviceUtilFunc.updateRelayData(
          state.selectedDevice,
          relay.RelayId,
          updateFunc,
        );
      } else if (state.selectedDevice.Id === masterDeviceId) {
        const childDevice = deviceUtilFunc.findChildDeviceById(
          relay.Relay?.DeviceId || "",
          state.selectedDevice,
        );
        if (childDevice) {
          deviceUtilFunc.updateChildDeviceRelay(
            childDevice,
            relay.RelayId,
            updateFunc,
          );
        }
      }
    },

    updateRelay: (
      state: any,
      action: PayloadAction<{ relay: Relay; masterDeviceId: string }>,
    ) => {
      const { relay, masterDeviceId } = action.payload;
      const updateFunc = (r: any) => {
        r.Name = relay.Name;
      };

      state.data.forEach((device: any) => {
        if (device.Id === relay.DeviceId) {
          deviceUtilFunc.updateRelayData(device, relay.Id, updateFunc);
        } else if (device.Id === masterDeviceId) {
          const childDevice = deviceUtilFunc.findChildDeviceById(
            relay.DeviceId || "",
            device,
          );
          if (childDevice) {
            deviceUtilFunc.updateChildDeviceRelay(
              childDevice,
              relay.Id,
              updateFunc,
            );
          }
        }
      });

      if (state.selectedDevice.Id === relay.DeviceId) {
        deviceUtilFunc.updateRelayData(
          state.selectedDevice,
          relay.Id,
          updateFunc,
        );
      } else if (state.selectedDevice.Id === masterDeviceId) {
        const childDevice = deviceUtilFunc.findChildDeviceById(
          relay.DeviceId || "",
          state.selectedDevice,
        );
        if (childDevice) {
          deviceUtilFunc.updateChildDeviceRelay(
            childDevice,
            relay.Id,
            updateFunc,
          );
        }
      }
    },
    updateDeviceModelInDevice: (state, action: PayloadAction<DeviceModel>) => {
      state.data.forEach((device) => {
        if (device.Id === action.payload.DeviceId) {
          device.DeviceModel = action.payload;
        }
      });
      state.selectedDevice.DeviceModel = action.payload;
    },
    updateDeviceName: (state, action: PayloadAction<DeviceName>) => {
      state.data.forEach((device) => {
        if (device.Id === action.payload.deviceId) {
          device.Name = action.payload.deviceName || "";
        } else if (device.Id === action.payload.masterDeviceId) {
          const childDevice = deviceUtilFunc.findChildDeviceById(
            action.payload.deviceId || "",
            device,
          );
          if (childDevice) {
            childDevice.Name = action.payload.deviceName;
          }
        }
      });
      if (state.selectedDevice.Id === action.payload.deviceId) {
        state.selectedDevice.Name = action.payload.deviceName || "";
      } else if (state.selectedDevice.Name === action.payload.masterDeviceId) {
        const childDevice = deviceUtilFunc.findChildDeviceById(
          action.payload.deviceId || "",
          state.selectedDevice,
        );
        if (childDevice) {
          childDevice.Name = action.payload.deviceName;
        }
      }
    },
    updateDeviceLocation: (state, action: PayloadAction<DeviceLocation>) => {
      state.data.forEach((device) => {
        if (device.Id === action.payload.deviceId) {
          device.Location = action.payload.location;
        }
      });
      if (state.selectedDevice.Id === action.payload.deviceId) {
        state.selectedDevice.Location = action.payload.location;
      }
    },
    removeArchiveDevice: (state, action: PayloadAction<string>) => {
      const dataIndex = state.data.findIndex((d) => d.Id === action.payload);
      if (dataIndex !== -1) {
        state.data.splice(dataIndex, 1);
      }
      const allDeviceIndex = state.allDevicesForModal.findIndex(
        (d) => d.Id === action.payload,
      );
      if (allDeviceIndex !== -1) {
        state.allDevicesForModal.splice(allDeviceIndex, 1);
      }
      state.activeDeviceId = "";
      state.selectedDevice = defaultSelectedSwrmDevice;
    },
    setSelectedDevice: (state, action: PayloadAction<SwrmDevices>) => {
      state.selectedDevice = { ...action.payload };
    },
    setDeviceGroup: (state, action: PayloadAction<DeviceGroup>) => {
      if (action.payload) {
        state.selectedDevice.Groups.push(action.payload);
        const index = state.data.findIndex(
          (x) => x.Id === state.selectedDevice.Id,
        );
        if (index !== -1) {
          state.data[index].Groups = state.selectedDevice.Groups;
        }
      }
    },
    removeDeviceGroup: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        const groupIndex = state.selectedDevice.Groups.findIndex(
          (g) => g.Id === action.payload,
        );
        state.selectedDevice.Groups.splice(groupIndex, 1);
        const index = state.data.findIndex(
          (x) => x.Id === state.selectedDevice.Id,
        );
        if (index !== -1) {
          state.data[index].Groups = state.selectedDevice.Groups;
        }
      }
    },
    resetSelectedDevice: (state) => {
      state.selectedDevice = defaultSelectedSwrmDevice;
    },
    setDeviceWifiStatusConnected: (state, action: PayloadAction<string>) => {
      state.selectedDevice.WifiStatus = true;
      state.data.map((d: SwrmDevices) => {
        if (d.Id === action.payload) {
          d.WifiStatus = true;
        }
      });
    },
    resetDevices: () => initialState,
  },
  extraReducers: (build) => {
    // GET DEVICE SLICES
    build.addCase(
      getAllDevices.pending,
      (
        state,
        action: PayloadAction<ResponseObject<SwrmDevices[]> | null | undefined>,
      ) => {
        state.isLoading = true;
      },
    );
    build.addCase(getAllDevices.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload !== null && action.payload !== undefined) {
        state.data = [...action.payload!];
      }
    });
    build.addCase(
      getAllBoardTypes.fulfilled,
      (
        state,
        action: PayloadAction<ResponseObject<BoardType[]> | null | undefined>,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          state.boardTypes = [...action.payload.Result];
        }
      },
    );
    build.addCase(getAllDevices.rejected, (state, action) => {
      state.isLoading = false;
    });
    build.addCase(getDevice.fulfilled, (state, action) => {
      if (action.payload!.Result) {
        state.selectedDevice = action.payload?.Result!;
        let updatedData = [...state.data];
        let index = updatedData.findIndex(
          (d) => d.Id == action.payload?.Result.Id,
        );
        updatedData.splice(index, 1, action.payload?.Result!);
        state.data = [...updatedData];
      }
    });
    build.addCase(filterDevices.fulfilled, (state, action) => {
      if (action.payload === undefined) {
        state.allDevicesForModal.splice(0, state.allDevicesForModal.length);
      } else {
        state.allDevicesForModal = [...action.payload!];
      }
    });
    build.addCase(
      patchDeviceLocation.fulfilled,
      (
        state,
        action: PayloadAction<ResponseObject<Location> | null | undefined>,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          state.selectedDevice.Location = action.payload.Result;
          state.selectedDevice.Groups = [];
        }
      },
    );
    build.addCase(
      syncRelays.fulfilled,
      (
        state,
        action: PayloadAction<
          ResponseObject<RelayWithValues[]> | null | undefined
        >,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          const relayWithValues: RelayWithValues[] = [...action.payload.Result];
          state.selectedDevice.RelayWithValues = relayWithValues;
        }
      },
    );
    build.addCase(
      getRoutines.fulfilled,
      (
        state,
        action: PayloadAction<ResponseObject<Routine[]> | null | undefined>,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          const routines: Routine[] = [...action.payload.Result];
          state.selectedDevice.Routines = routines;
        }
      },
    );
    build.addCase(
      getRoutineSteps.fulfilled,
      (
        state,
        action: PayloadAction<ResponseObject<Step[]> | null | undefined>,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          const routineSteps: Step[] = [...action.payload.Result];
          state.selectedDevice.Steps = routineSteps;
        }
      },
    );
    build.addCase(
      getRoutineStepRelays.fulfilled,
      (
        state,
        action: PayloadAction<ResponseObject<number[][]> | null | undefined>,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          const stepRelayIds: number[][] = [...action.payload.Result];
          state.selectedDevice.StepRelayIds = stepRelayIds;
        }
      },
    );
    build.addCase(
      deleteRoutine.fulfilled,
      (
        state,
        action: PayloadAction<ResponseObject<string> | null | undefined>,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          state.selectedDevice.Routines = [];
          state.selectedDevice.Steps = [];
          state.selectedDevice.StepRelayIds = [];
        }
      },
    );
  },
});

export const {
  setAllDevices,
  setSelectedDevice,
  resetSelectedDevice,
  changeDevicesOrder,
  updateSensorsInDevice,
  updateDeviceName,
  updateDeviceLocation,
  updateDeviceModelInDevice,
  updateRelayInDevice,
  updateRelay,
  setActiveDeviceImageCard,
  setDeviceGroup,
  removeDeviceGroup,
  resetDeviceImageCard,
  removeArchiveDevice,
  setDeviceWifiStatusConnected,
  resetDevices,
} = devicesSlice.actions;

export const selectDevices = (state: RootState) => state.devices;
export const isDeviceLoading = (state: RootState) => state.devices.isLoading;

export default devicesSlice.reducer;
