import React, { useState } from "react";
import {
  exportSensorData,
  getDeviceConnectionData,
} from "../../services/dashboard/dashboard.service";
import { ScaledButtonWrapper } from "../../components/modals/CustomModal.style";
import { generateFileDateTimezoneName } from "../../utils/dateUtils";
interface Props {
  fileName: string;
  customButtonName: string;
  deviceId: string;
}
export default function ExportCSVDataBtn({
  fileName,
  customButtonName,
  deviceId,
}: Props) {
  // State to track if data export is in progress
  const [isDataExporting, setIsDataExporting] = useState<boolean>(false);
  // Function to handle exporting sensor data
  const exportData = async () => {
    // If data is currently exporting, prevent duplicate export attempts
    if (isDataExporting) return;

    setIsDataExporting(true);

    try {
      // Fetch sensor data from the server
      const exportedData = await getDeviceConnectionData(deviceId);

      // Check if data was received
      if (!exportedData) {
        throw new Error("No data received");
      }

      // Create a Blob object from the response data
      const blob = new Blob([exportedData], { type: "text/csv" });

      // Construct a more descriptive filename with a timestamp
      const finalFileName = `${fileName}-${generateFileDateTimezoneName()}.csv`;

      // Create a temporary URL for the blob object
      const url = URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", finalFileName); // Set the filename for download

      // Append the link to the body and programmatically click it to initiate download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link and revoke the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setIsDataExporting(false);
    } catch (error) {
      console.error("Error exporting csv data:", error);
      setIsDataExporting(false);
      // Handle errors here (e.g., show error message to the user)
    }
  };
  return (
    <ScaledButtonWrapper style={{ justifyContent: "flex-end" }}>
      <button onClick={exportData}>
        {isDataExporting ? "Exporting..." : customButtonName || "Export Data"}
      </button>
    </ScaledButtonWrapper>
  );
}
