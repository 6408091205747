import styled from "styled-components";

export const CustomImageCard = styled.div`
  border: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 4px;
  color: #fff;
  padding: 8px;
  max-width: 100%;
  width: 16.6%;
  min-width: 16.6%;
  height: 100%;
`;

export const NavigationButtonContainer = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: space-between;
`;

export const NavigationButton = styled.button`
  background-color: #fff;
  border: none;
  padding: 8px;
`;

export const CardContent = styled.div`
  padding: 8px;
  height: 40%;
`;

export const Header = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-weight: ${({ theme }) => {
    return theme.fontWeight["bold"];
  }};
  font-size: ${(props) => props.theme.fontSizes.xs};
`;

export const SecondaryHeader = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
`;
