import { IoMdDownload } from "react-icons/io";
import {
  BiInfoCircle,
  BiSolidArchiveIn,
  BiSolidArchiveOut,
} from "react-icons/bi";
import { TbFocusCentered } from "react-icons/tb";
import styled from "styled-components";

export const ImageWrapper = styled.div`
  width: 100%;
  height: 60%;
  text-align: center;
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ImageInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 40%;
`;

export const InfoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem;
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 4%;
  justify-content: space-evenly;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

export const StyledTbFocusCentered = styled(TbFocusCentered)`
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledBiSolidArchiveIn = styled(BiSolidArchiveIn)`
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledBiSolidArchiveOut = styled(BiSolidArchiveOut)`
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledIoMdDownload = styled(IoMdDownload)`
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledBiInfoCircle = styled(BiInfoCircle)`
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
  &.disabled {
    color: grey;
    cursor: not-allowed;
    &:hover {
      color: grey;
    }
  }
`;
