import { FiLock, FiEdit, FiSave } from "react-icons/fi";
import { MdOutlineLockReset } from "react-icons/md";
import { ReactComponent as MFA } from "../../assets/MFA Icon2.svg";
import styled from "styled-components";

export const StyledMdOutlineLockReset = styled(MdOutlineLockReset)`
  font-size: ${(props) => props.theme.fontSizes["sm"]};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;
export const StyledMFAIcon = styled(MFA)`
  font-size: ${(props) => props.theme.fontSizes["md"]};
  width: 1.33rem;
  height: 1.33rem;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;
