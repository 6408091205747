import React from "react";
import SearchInput from "../../input/SearchInput";
import { Typography } from "../../typrography/Typography";
import tokenGenerationTable from "../../../data/tokenGenerationTable";
import TokenGenerationTable from "../../tables/TokenGenerationTable";
import { TableWrapper } from "../../tables/Table.style";
import { Button } from "../../button/Button";
import { GoKey } from "react-icons/go";
import { AddKeyButtonWrapper } from "../../tables/table-button/TableButton.style";
import { DefaultTheme, useTheme } from "styled-components";

type Props = {};

const TokenGeneration = (props: Props) => {
  const theme: DefaultTheme = useTheme();
  const columns = ["Secure Key", "Created", "Last Used", ""];
  const handleItemCheckChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {};

  const handleSearch = () => {
    console.log("handleSearch clicked");
    // To be implemented later.
  };

  return (
    <>
      <SearchInput
        label={""}
        placeHolder={"Search Page"}
        searchIcon={true}
        handleSearch={handleSearch}
      ></SearchInput>
      <Typography
        variant="lg"
        display="flex"
        weight="black"
        direction="flex-start"
        style={{ width: "100%" }}
      >
        API Keys
      </Typography>
      <Typography
        variant="md"
        display="flex"
        color="primary"
        direction="flex-start"
        style={{ width: "100%" }}
      >
        Your secure API keys can be viewed below. Please note that SWRM will not
        display your personal keys again after creating them.
      </Typography>
      <Typography
        variant="md"
        display="flex"
        color="primary"
        direction="flex-start"
        style={{ width: "100%" }}
      >
        Note: Do not share your API key publicly or expose it in the browser.
      </Typography>
      <TableWrapper>
        <TokenGenerationTable
          columns={columns}
          data={tokenGenerationTable}
          handleItemCheckChange={handleItemCheckChange}
          width={"50%"}
          trBorder={theme.border["primary-sm"]}
          tdColor={theme.colors.primary}
        />
      </TableWrapper>
      <AddKeyButtonWrapper>
        <Button scaled={true} borderRadius="3px" size="xs">
          <GoKey size={"22px"} />
          Add Key
        </Button>
      </AddKeyButtonWrapper>
    </>
  );
};

export default TokenGeneration;
