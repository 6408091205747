import CustomModal from "../CustomModal";
import { Typography } from "../../typrography/Typography";
import { ModalProvider } from "styled-react-modal";
import { ScaledButtonWrapper } from "../CustomModal.style";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { StyledFormikForm } from "../../tab/ai-model-manager/Upload.style";
import { FormControl } from "../../form-field/form-control/FormControl";
import { DeviceInfoWrapper } from "../deploy-device-modal/DeployDeviceModal.styled";
import { Location } from "../../../interfaces/device/Location";
import Input from "../../input/Input";
import { FormLabel } from "../../form-field/form-label/FormLabel";
import {
  FilterDropdown,
  FilterOption,
} from "../../dropdown/filter-dropdown/FilterDropdown";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getLocationTypeDropdown } from "../../../store/slices/dropdownOptions/dropdownListSlice";
import { patchLocation } from "../../../services/locations/Location.service";
import { ResponseObject } from "../../../interfaces/response/Response";
import React, { useState } from "react";

interface Props {
  isOpenModel: boolean;
  onClose: () => void;
  location: Location;
}

interface FormValues {
  name: string;
  locationTypeId: number;
  address: string;
  latitude: string;
  longitude: string;
}

const EditLocationInfoModal = ({ isOpenModel, onClose, location }: Props) => {
  const [isApiCalling, setIsApiCalling] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const locationTypeDropdown = useAppSelector(getLocationTypeDropdown);

  const selectedLocationType: FilterOption | undefined =
    locationTypeDropdown?.options.find((item) => {
      return item.value === location.LocationTypeId;
    });

  const initialValues: FormValues = {
    name: !location?.Name?.toLocaleLowerCase()?.includes("unnamed device")
      ? location.Name
      : "",
    locationTypeId: location.LocationTypeId,
    address: location?.Address || "",
    latitude:
      location.GPSLatitude !== null ? location.GPSLatitude.toFixed(7) : "",
    longitude:
      location.GPSLongitude !== null ? location.GPSLongitude.toFixed(7) : "",
  };

  /**
   * Validation schema for the form.
   *
   * - 'name' field is required.
   * - 'name' must have a minimum length of 2 characters.
   * - 'name' can have a maximum length of 50 characters.
   */

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must have at least 2 characters")
      .max(50, "Name must have less than 50 characters"),
    latitude: Yup.number().min(-90, "-90° to 90°").max(90, "-90° to 90°"),
    longitude: Yup.number()
      .min(-180, "-180° to 180°")
      .max(180, "-180° to 180°"),
  });

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0.725rem", fontWeight: "700" }}
      >
        Edit Location Info
      </Typography>
    );
  };

  const cardFooter = () => {
    return (
      <ScaledButtonWrapper
        buttonMargin="0.5rem"
        style={{ justifyContent: "end" }}
      >
        <button type="submit" disabled={isApiCalling} form="select-device">
          {isApiCalling ? "Saving..." : "Save"}
        </button>
      </ScaledButtonWrapper>
    );
  };

  const handleLocationUpdate = async (
    locationObj?: Location,
    revert?: () => void,
  ) => {
    if (locationObj) {
      const result = await dispatch(patchLocation(locationObj));
      const response: ResponseObject<Location> = result.payload;

      if (response.IsError) revert!();
    }
  };

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      setIsApiCalling(true);
      actions.setSubmitting(true);
      const updatedLocationInfo: Location = {
        ...location,
        Name: values.name,
        LocationTypeId: values.locationTypeId || location.LocationTypeId,
        Address: values.address,
        GPSLatitude: parseFloat(values.latitude),
        GPSLongitude: parseFloat(values.longitude),
      };

      await handleLocationUpdate(updatedLocationInfo);
      onClose();
    } catch (error) {
      console.error("Error updating enabled status:", error);
    }
    setIsApiCalling(false);
    actions.setSubmitting(false);
  };
  return (
    <ModalProvider>
      <CustomModal
        isOpen={isOpenModel}
        onClose={onClose}
        header={cardHeader()}
        footer={cardFooter()}
        height="80vh"
      >
        <DeviceInfoWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <StyledFormikForm id={"select-device"}>
                <FormControl>
                  <FormLabel htmlFor="name">Location Name</FormLabel>
                  <Input
                    name="name"
                    value={values.name}
                    placeHolder="Enter location name"
                    width="100%"
                    inputWrapperWidth={"100%"}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="" style={{ marginBottom: "20px" }}>
                    Location Type
                  </FormLabel>
                  <FilterDropdown
                    isFullSize
                    margin={"0 0 20px 0"}
                    defaultValue={
                      selectedLocationType
                        ? selectedLocationType
                        : { label: "", value: 0 }
                    }
                    title="Location Type"
                    options={locationTypeDropdown?.options || []}
                    onChange={({ value }: FilterOption) => {
                      setFieldValue("locationTypeId", Number(value));
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="latitude">Latitude (GPS)</FormLabel>
                  <Input
                    name="latitude"
                    value={values.address}
                    placeHolder="Enter location latitude"
                    width="100%"
                    inputWrapperWidth={"100%"}
                    type="number"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="longitude">Longitude (GPS)</FormLabel>
                  <Input
                    name="longitude"
                    value={values.address}
                    placeHolder="Enter location longitude"
                    width="100%"
                    inputWrapperWidth={"100%"}
                    type="number"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="address">Address</FormLabel>
                  <Input
                    name="address"
                    value={values.address}
                    placeHolder="Enter location address"
                    width="100%"
                    inputWrapperWidth={"100%"}
                  />
                </FormControl>
              </StyledFormikForm>
            )}
          </Formik>
        </DeviceInfoWrapper>
      </CustomModal>
    </ModalProvider>
  );
};

export default EditLocationInfoModal;
