import { DefaultTheme } from "styled-components";
import { DefaultThemeBorder, DefaultThemeFontSize } from "../../../app/Theme";

export const multiSelectDropdownStyle = (theme: DefaultTheme) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    maxHeight: "1.5rem",
    width: "100%",
    overflowY: "scroll",
    overflowX: "none",
    backgroundColor: "#020A01",
    border: theme.border["primary-sm"],
    borderRadius: "5px",
    fontSize: theme.fontSizes.sm,
    boxShadow: "none",
    cursor: "pointer",

    "&:hover": {
      borderColor: theme.colors.secondary,
    },
    "&::-webkit-scrollbar": {
      display: "block",
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#444444",
      borderRadius: "2px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: "#707070",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#020A01",
    border: theme.border["primary-sm"],
    borderRadius: "5px",
    outline: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#FFF",
    cursor: "pointer",
    backgroundColor: state.isSelected && theme.colors.primary,

    "&:hover": {
      backgroundColor: theme.colors.primary,
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    display: "flex",
    backgroundColor: theme.colors.primary,
    color: "#000",
    paddingInline: "0.5rem",
    borderRadius: "25px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "#000",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "#000",
    backgroundColor: theme.colors.primary,
    "&:hover": {
      backgroundColor: theme.colors.secondary,
      color: "#FFF",
    },
  }),
});

export const multiSelectDropdownStyleDisabled = (theme: DefaultTheme) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    maxHeight: "1.5rem",
    width: "100%",
    backgroundColor: theme.colors["black-100"],
    border: theme.border["border-sm-gray"],
    borderRadius: "5px",
    fontSize: theme.fontSizes.sm,
    boxShadow: "none",
    PointerEvent: "auto",
    cursor: "not-allowed",
    "&::-webkit-scrollbar": {
      display: "block",
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#444444",
      borderRadius: "2px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.colors["grey-500"],
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    backgroundColor: theme.colors["black-100"],
    border: theme.border["primary-sm"],
    borderRadius: "5px",
    outline: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#FFF",
    backgroundColor: state.isSelected && theme.colors.primary,

    "&:hover": {
      backgroundColor: theme.colors.primary,
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    display: "flex",
    backgroundColor: theme.colors.primary,
    color: "#000",
    paddingInline: "0.5rem",
    borderRadius: "25px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "#000",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "#000",
    backgroundColor: theme.colors.primary,
    "&:hover": {
      backgroundColor: theme.colors.secondary,
      color: "#FFF",
    },
  }),
});
