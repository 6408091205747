import React from "react";
import { StyledFaPowerOff } from "../tables/Table.style";
import { Tooltip } from "react-tooltip";
import { CustomIconButton } from "../button/icon-button/CustomIconButton";

interface PowerOffIconProps {
  handlePowerOffOnClick?: () => void;
  size?: string;
}

const PowerOffIcon: React.FC<PowerOffIconProps> = ({
  handlePowerOffOnClick,
  size,
}) => {
  return (
    <CustomIconButton color={"default"} onClick={handlePowerOffOnClick}>
      <StyledFaPowerOff
        size={size}
        data-tooltip-id="powerOff-tooltip"
        data-tooltip-content="Power Off"
        data-tooltip-place="top"
      />
      <Tooltip id="powerOff-tooltip" />
    </CustomIconButton>
  );
};

export default PowerOffIcon;
