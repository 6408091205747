export function encodeString(stringData: string) {
  let encodedString = stringData;
  for (let i = 0; i < 3; i++) {
    encodedString = btoa(encodedString);
  }

  return encodedString;
}

export function decodeString(encodedString: string) {
  let decodedString = encodedString;
  for (let i = 0; i < 3; i++) {
    decodedString = atob(decodedString);
  }

  return decodedString;
}
