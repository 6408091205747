import React from "react";
import { Tooltip } from "react-tooltip";
import { CustomIconButton } from "../button/icon-button/CustomIconButton";
import { StyledMdOutlineLockReset } from "./Icons.style";

interface ResetPasswordIconProps {
  handleOnClick: () => void;
  size: string;
  tooltip?: string;
  disabled?: boolean;
}

const ResetPasswordIcon: React.FC<ResetPasswordIconProps> = ({
  handleOnClick,
  size,
  tooltip,
  disabled,
}) => {
  return (
    <CustomIconButton onClick={handleOnClick}>
      <StyledMdOutlineLockReset
        data-tooltip-id="resetpassword-tooltip"
        data-tooltip-content={tooltip || "Reset Password"}
        data-tooltip-place="top"
        size={size}
        className={disabled ? "disabled" : ""}
      />
      <Tooltip id="resetpassword-tooltip" />
    </CustomIconButton>
  );
};

export default ResetPasswordIcon;
