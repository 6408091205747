import axios, { AxiosError, AxiosResponse } from "axios";
import { DeviceConfiguration } from "../../interfaces/device/DeviceConfiguration";
import {
  DeviceConfigurationWifi,
  WifiConnectionResultPayload,
} from "../../interfaces/device/DeviceConfigurationWifi";
import { OpenErrorNotification } from "../../components/notification/Notification";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { ResponseObject } from "../../interfaces/response/Response";
import {
  validateErrorResult,
  validateFetchErrorResult,
  validateFetchSuccessResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";
import { setDeviceWifiStatusConnected } from "../../store/slices/devices/devicesSlice";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

const getDeviceConfig = async (
  id: number,
  deviceId: string,
  boardTypes: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const searchString = `id${id > 0 ? "=" + id : ""}&deviceId${
    deviceId.length > 0 ? "=" + deviceId : ""
  }&boardTypes${boardTypes > 0 ? "=" + boardTypes : ""}`;

  const response = await axios
    .get(`${API_URL}/DeviceConfiguration?${searchString}`)
    .then(
      async (response: AxiosResponse<ResponseObject<DeviceConfiguration>>) => {
        // Validate Success Result
        const result = response.data;
        await validateFetchSuccessResult(response);

        return result;
      },
    )
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateFetchErrorResult(error, dispatch);
    });

  return response;
};

const getWifiConnectionResult = async (
  deviceId: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .get(
      `${API_URL}/DeviceConfiguration/getWifiConnectionResult?deviceId=${deviceId}`,
    )
    .then(
      async (
        response: AxiosResponse<ResponseObject<WifiConnectionResultPayload>>,
      ) => {
        // Validate Success Result
        const setStatus = async () => {
          dispatch(setDeviceWifiStatusConnected(deviceId));
        };

        const result = response.data;
        await validateFetchSuccessResult(response, setStatus);

        return result;
      },
    )
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateFetchErrorResult(error, dispatch);
    });

  return response;
};

const sendFirmwareUpdate = async (
  deviceId: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(
      `${API_URL}/DeviceConfiguration/sendFirmwareUpdate?deviceId=${deviceId}`,
    )
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Firmware update sent to device.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const sendRebootRequest = async (
  deviceId: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(
      `${API_URL}/DeviceConfiguration/sendRebootRequest?deviceId=${deviceId}`,
    )
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Reboot request sent to device.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const powerOffDevice = async (
  deviceId: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/DeviceConfiguration/powerOffDevice?deviceId=${deviceId}`)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Device powered off.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

/* Commented out unused method for the time being */
// const patchDeviceConfig = async (deviceConfig: DeviceConfiguration) => {
//   try {
//     let response = await axios
//       .patch(`${API_URL}/DeviceConfiguration`)
//       .then((response) => {
//         console.log(response.data.Result);
//         return response.data.Result;
//       });
//     return response;
//   } catch (error: unknown) {
//     return error;
//   }
// };

const patchDeviceConfigWifi = async (
  deviceConfig: DeviceConfigurationWifi,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(
      `${API_URL}/DeviceConfiguration/updateWifiConfiguration`,
      deviceConfig,
    )
    .then(
      async (response: AxiosResponse<ResponseObject<DeviceConfiguration>>) => {
        // Validate Success Result
        const result = response.data;
        const successMessage: string = "Wi-Fi credentials sent to device.";
        await validateSuccessResult(response, dispatch, successMessage);

        return result;
      },
    )
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

/* Commented out unused method for the time being */
// const patchDeviceConfigExposure = async (
//   deviceId: string,
//   exposure: number,
// ) => {
//   try {
//     let response = await axios
//       .patch(`${API_URL}/DeviceConfiguration/Exposure`, {
//         Item1: deviceId,
//         Item2: exposure,
//       })
//       .then((response) => {
//         console.log(response.data);
//         return response.data;
//       });
//     return response;
//   } catch (error: unknown) {
//     return error;
//   }
// };

/* Commented out unused method for the time being */
// const patchDeviceConfigCoolDown = async (
//   deviceId: string,
//   coolDown: number,
// ) => {
//   try {
//     let response = await axios
//       .patch(`${API_URL}/DeviceConfiguration/CoolDown`, {
//         Item1: deviceId,
//         Item2: coolDown,
//       })
//       .then((response) => {
//         console.log(response.data);
//         return response.data;
//       });
//     return response;
//   } catch (error: unknown) {
//     return error;
//   }
// };

/* Commented out unused method for the time being */
// const patchDeviceConfigLocation = async (
//   deviceId: string,
//   locationId: number,
// ) => {
//   try {
//     let response = await axios
//       .patch(`${API_URL}/DeviceConfiguration/Location`, {
//         Item1: deviceId,
//         Item2: locationId,
//       })
//       .then((response) => {
//         console.log(response.data);
//         return response.data;
//       });
//     return response;
//   } catch (error: unknown) {
//     return error;
//   }
// };

const patchDeviceConfigName = async (
  deviceId: string,
  newDeviceName: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/device/updateTagName`, {
      DeviceId: deviceId,
      NewTagName: newDeviceName,
    })
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Device updated successfully.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const deviceConfigService = {
  getDeviceConfig,
  getWifiConnectionResult,
  sendFirmwareUpdate,
  sendRebootRequest,
  powerOffDevice,
  // patchDeviceConfig,
  patchDeviceConfigWifi,
  // patchDeviceConfigExposure,
  // patchDeviceConfigCoolDown,
  // patchDeviceConfigLocation,
  patchDeviceConfigName,
};

export default deviceConfigService;
