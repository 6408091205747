import React from "react";
import { Tooltip } from "react-tooltip";
import { CustomIconButton } from "../button/icon-button/CustomIconButton";
import { StyledMFAIcon } from "./Icons.style";

interface ResetMFAIconProps {
  handleOnClick: () => void;
  size: string;
  tooltip?: string;
  disabled?: boolean;
}

const ResetMFAIcon: React.FC<ResetMFAIconProps> = ({
  handleOnClick,
  size,
  tooltip,
  disabled,
}) => {
  return (
    <CustomIconButton onClick={handleOnClick}>
      <StyledMFAIcon
        data-tooltip-id="resetmfa-tooltip"
        data-tooltip-content={tooltip || "Reset MFA"}
        data-tooltip-place="top"
        className={disabled ? "disabled" : ""}
      />
      <Tooltip id="resetmfa-tooltip" />
    </CustomIconButton>
  );
};

export default ResetMFAIcon;
