import React from "react";
import "styled-components";
import { DefaultTheme, ThemeProvider } from "styled-components";
import "./App.css";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";

export interface ThemeProps {
  children: React.ReactNode[] | React.ReactNode;
}

declare module "styled-components" {
  export interface DefaultTheme {
    colors: {
      primary: string;
      secondary: string;
      white: string;
      "violet-100": string;
      "violet-200": string;
      "violet-300": string;
      "violet-400": string;
      "violet-500": string;
      "violet-600": string;
      "violet-700": string;
      "violet-800": string;
      "violet-900": string;
      "grey-100": string;
      "grey-200": string;
      "grey-300": string;
      "grey-400": string;
      "grey-500": string;
      "grey-600": string;
      "grey-700": string;
      "grey-800": string;
      "grey-900": string;
      "red-100": string;
      "red-200": string;
      "red-300": string;
      "green-100": string;
      "green-200": string;
      "green-300": string;
      "blue-100": string;
      "blue-700": string;
      "blue-800": string;
      "blue-900": string;
      "indigo-100": string;
      "yellow-100": string;
      "pink-100": string;
      "black-100": string;
      "black-200": string;
      none: string;
      [key: string]: string; // Allow additional colors
    };
    border: {
      none: string;
      "border-xl-violet": string;
      "border-sm-white": string;
      "border-sm-gray": string;
      "border-sm-dark-gray": string;
      "border-sm-green": string;
      "border-sm-yellow": string;
      "border-md-yellow": string;
      "border-xl-yellow": string;
      "primary-sm": string;
      "primary-md": string;
      "primary-xl": string;
      "secondary-sm": string;
      "secondary-md": string;
    };
    fontSizes: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      "2xl": string;
      "3xl": string;
      "4xl": string;
      "5xl": string;
      "6xl": string;
      "7xl": string;
      "8xl": string;
      "9xl": string;
    };
    borderRadius: {
      "rounded-sm": string;
      "rounded-md": string;
      "rounded-lg": string;
      "rounded-xl": string;
      "rounded-2xl": string;
      "rounded-3xl": string;
      "rounded-4xl": string;
      "rounded-5xl": string;
      "rounded-full": string;
    };
    boxShadow: any;
    spacing: {
      xxs: string;
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      xxl: string;
    };
    fontWeight: {
      normal: string;
      light: string;
      medium: string;
      semiBold: string;
      bold: string;
      extraBold: string;
      black: string;
    };
    breakpoints: {
      sm: string;
      md: string;
      lg: string;
      xl: string;
      xxl: string;
      xxxl: string;
    };
    mediaQuery: {
      sm: string;
      md: string;
      lg: string;
      xl: string;
      xxl: string;
      retina: string;
    };
  }
}

// Default Colors
export const DefaultThemeColor = {
  primary: "#EDAE49",
  secondary: "#00916E",
  white: "#ffffff",
  "violet-100": "#DA9EDD",
  "violet-200": "#D47FE9",
  "violet-300": "#9E7EA7",
  "violet-400": "#6E4C70",
  "violet-500": "#554759",
  "violet-600": "#4A364C",
  "violet-700": "#302331",
  "violet-800": "#231C23",
  "violet-900": "#0A010D",
  "grey-100": "#F4F4F4",
  "grey-200": "#F5F5F5",
  "grey-300": "#BABABA",
  "grey-400": "#9E9E9E",
  "grey-500": "#707070",
  "grey-600": "#737373",
  "grey-700": "#848484",
  "grey-800": "#444444",
  "grey-900": "#111817",
  "red-100": "#E00707",
  "red-200": "#CB350E",
  "red-300": "#D64550",
  "green-100": "#80D39B",
  "green-200": "#3EB23E",
  "green-300": "#00916E",
  "blue-100": "#0742A6",
  "blue-700": "#2A8FB4",
  "blue-900": "#4454ea",
  "blue-800": "#5F5DBB",
  "indigo-100": "#2323C6",
  "yellow-100": "#EDAE49",
  "pink-100": "#C623A5",
  "black-100": "#020A01",
  "black-200": "#111817",
  none: "none",
};

// Default Border Styles
export const DefaultThemeBorder = {
  none: "none",
  "border-xl-violet": `7px solid ${DefaultThemeColor["violet-300"]}`,
  "border-sm-white": `1px solid ${DefaultThemeColor["grey-500"]}`,
  "border-sm-gray": `1px solid ${DefaultThemeColor["grey-500"]}`,
  "border-sm-dark-gray": `1px solid ${DefaultThemeColor["grey-500"]}`,
  "border-sm-green": `1px solid ${DefaultThemeColor.secondary}`,
  "border-sm-yellow": `1px solid ${DefaultThemeColor.primary}`,
  "border-md-yellow": `2px solid ${DefaultThemeColor.primary}`,
  "border-xl-yellow": `4px solid ${DefaultThemeColor.primary}`,
  "primary-sm": `1px solid ${DefaultThemeColor.primary}`,
  "primary-md": `2px solid ${DefaultThemeColor.primary}`,
  "primary-xl": `4px solid ${DefaultThemeColor.primary}`,
  "secondary-sm": `1px solid ${DefaultThemeColor.secondary}`,
  "secondary-md": `1px solid ${DefaultThemeColor.secondary}`,
};

// Default Font Sizes
export const DefaultThemeFontSize = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  "2xl": "1.5rem",
  "3xl": "1.875rem",
  "4xl": "2.25rem",
  "5xl": "3rem",
  "6xl": "3.75rem",
  "7xl": "4.5rem",
  "8xl": "6rem",
  "9xl": "8rem",
};

// Default Breakpoints
export const DefaultThemeBreakpoints = {
  sm: "48em",
  md: "64em",
  lg: "85.375em",
  xl: "120em",
  xxl: "160em",
  xxxl: "180em",
};

// The build final theme structure, pulling in Redux custom color theme dynamically
const buildTheme = (customThemeColor: string): DefaultTheme => {
  const primaryColor = customThemeColor ?? DefaultThemeColor.primary;
  return {
    colors: {
      ...DefaultThemeColor,
      primary: primaryColor,
    },
    fontSizes: DefaultThemeFontSize,
    borderRadius: {
      "rounded-sm": "2px",
      "rounded-md": "4px",
      "rounded-lg": "6px",
      "rounded-xl": "8px",
      "rounded-2xl": "12px",
      "rounded-3xl": "16px",
      "rounded-4xl": "24px",
      "rounded-5xl": "32px",
      "rounded-full": "9999px",
    },
    boxShadow: {},
    spacing: {
      xxs: "4px",
      xs: "8px",
      sm: "12px",
      md: "16px",
      lg: "20px",
      xl: "24px",
      xxl: "32px",
    },
    border: {
      none: "none",
      "border-xl-violet": `7px solid ${DefaultThemeColor["violet-300"]}`,
      "border-sm-white": `1px solid ${DefaultThemeColor["grey-500"]}`,
      "border-sm-gray": `1px solid ${DefaultThemeColor["grey-500"]}`,
      "border-sm-dark-gray": `1px solid ${DefaultThemeColor["grey-500"]}`,
      "border-sm-green": `1px solid ${DefaultThemeColor.secondary}`,
      "border-sm-yellow": `1px solid ${primaryColor}`,
      "border-md-yellow": `2px solid ${primaryColor}`,
      "border-xl-yellow": `4px solid ${primaryColor}`,
      "primary-sm": `1px solid ${primaryColor}`,
      "primary-md": `2px solid ${primaryColor}`,
      "primary-xl": `4px solid ${primaryColor}`,
      "secondary-sm": `1px solid ${DefaultThemeColor.secondary}`,
      "secondary-md": `1px solid ${DefaultThemeColor.secondary}`,
    },
    fontWeight: {
      normal: "400",
      light: "300",
      medium: "500",
      semiBold: "600",
      bold: "700",
      extraBold: "800",
      black: "900",
    },
    breakpoints: DefaultThemeBreakpoints,
    mediaQuery: {
      sm: `(min-width: ${DefaultThemeBreakpoints.sm})`,
      md: `(min-width: ${DefaultThemeBreakpoints.md})`,
      lg: `(min-width: ${DefaultThemeBreakpoints.lg})`,
      xl: `(min-width: ${DefaultThemeBreakpoints.xl})`,
      xxl: `(min-width: ${DefaultThemeBreakpoints.xxl})`,
      retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)",
    },
  };
};

export const Theme: React.FC<ThemeProps> = ({ children }) => {
  const customTheme = useAppSelector(
    (state: RootState) =>
      state.persisted?.auth?.companyTheme?.ThemeColor ||
      DefaultThemeColor.primary,
  );
  const themeFromRedux = buildTheme(customTheme);

  return <ThemeProvider theme={themeFromRedux}>{children}</ThemeProvider>;
};
