import {
  ColorPickerWrapper,
  CompanyLogoWrapper,
  ThemeWrapper,
} from "./Theme.style";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { FlexContainer } from "../../flex-container/FlexContainer";
import { ScaledButtonWrapper } from "../../modals/CustomModal.style";
import { Typography } from "../../typrography/Typography";
import { useCallback, useEffect, useRef, useState } from "react";
import { DeleteMedia, UploadMedia } from "../../../services/media/Media.Upload";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { CompanyTheme } from "../../../interfaces/company/Company";
import {
  companyService,
  getCompanyTheme,
} from "../../../services/company/company.service";
import {
  setCompanyTheme,
  setLoadCompanyLogo,
  setPresignedCompanyLogoUrl,
} from "../../../store/slices/auth/authSlice";
import { ResponseObject } from "../../../interfaces/response/Response";
import Loader from "../../loader/Loader";
import { Image } from "../../image/Image";
import { StyledMdEdit } from "./Account.style";
import SwrmNavLogo from "../../../assets/SWRM_Logo_512x80_Yellow.png";
import { awsUtils } from "../../../utils/awsUtils";
import { OpenWarningNotification } from "../../notification/Notification";
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from "../../input/upload-input/DragUpload";
import { formatBytes } from "../../../utils";
import { Media } from "../../../interfaces/device/Media";
import { handleLogoImageFallback } from "../../../utils/imageUtils";

const Themes = () => {
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const auth = useAppSelector((state) => state.persisted.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [color, setColor] = useColor(auth.companyTheme.ThemeColor);
  const allowedFileType: string[] = ["image"];

  const handleLogoEditClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileUpload = async (file: File) => {
    try {
      setIsLoading(true);
      setLoadingMessage("Updating Company Profile");

      if (file) {
        // Check if Company has a previous company logo uploaded
        if (auth.companyTheme.MediaId) {
          // Delete Media
          await DeleteMedia(auth.companyTheme.MediaId);
        }

        // Upload Media
        const response: ResponseObject<Media> | undefined | null =
          await UploadMedia("Media", file);
        if (response != undefined && response != null && response.Result.Id) {
          // Update Media in Company Theme
          const mediaUpdateResponse: ResponseObject<CompanyTheme> =
            await companyService.updateCompanyLogo(
              dispatch,
              response.Result.Id,
            );
          dispatch(setCompanyTheme(mediaUpdateResponse.Result));
          dispatch(setLoadCompanyLogo(true));
        }
      } else {
        OpenWarningNotification("Please add image");
      }
    } catch (error) {
      console.error("handleFileUpload Error:", error);
    }

    setIsLoading(false);
    setLoadingMessage("");
  };

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        handleFileUploading(file);
      }
    },
    [handleFileUpload],
  );

  const handleFileUploading = (file: File) => {
    if (validateFileType(file)) {
      if (file.size <= DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
        handleFileUpload(file);
      } else {
        OpenWarningNotification(
          `File must smaller than ${formatBytes(
            DEFAULT_MAX_FILE_SIZE_IN_BYTES,
          )}`,
        );
        resetFileInput();
      }
    }
  };

  const validateFileType = (file: File) => {
    const inputFileType: string = file?.type?.split("/")[0];
    if (inputFileType && allowedFileType.includes(inputFileType)) {
      return true;
    } else {
      const msg = allowedFileType.join(", ");
      OpenWarningNotification(
        `Only ${msg} ${allowedFileType.length > 1 ? "files" : "file"} allowed!`,
      );
      resetFileInput();
      return false;
    }
  };

  const resetFileInput = () => {
    if (fileInputRef?.current?.value) {
      fileInputRef.current.value = "";
    }
  };

  const fetchCompanyTheme = async () => {
    setIsLoading(true);
    dispatch(getCompanyTheme());
    await fetchSignedImageURL();
    setIsLoading(false);
  };

  const fetchSignedImageURL = async () => {
    if (auth.companyTheme.Media) {
      const signedUrl: string | null = await awsUtils.getSignedImageURL(
        auth.companyTheme.Media.Url,
      );
      dispatch(setPresignedCompanyLogoUrl(signedUrl ?? ""));
      dispatch(setLoadCompanyLogo(false));
    }
  };

  const handleCompanyLogoImageOnError = async () => {
    if (auth.companyTheme.Media) {
      dispatch(setLoadCompanyLogo(true));
      const signedUrl: string | null = await handleLogoImageFallback(
        auth.companyTheme.Media.Url,
      );
      dispatch(setPresignedCompanyLogoUrl(signedUrl ?? ""));
      dispatch(setLoadCompanyLogo(false));
    }
  };

  const handleThemeColorChange = async () => {
    if (color.hex.toUpperCase() !== auth.companyTheme.ThemeColor) {
      setLoadingMessage("Saving theme settings");
      setIsLoading(true);
      const responseData: ResponseObject<CompanyTheme> =
        await companyService.updateColorTheme(
          dispatch,
          color.hex.toUpperCase(),
        );
      dispatch(setCompanyTheme(responseData.Result));
    }

    setLoadingMessage("");
    setIsLoading(false);
  };

  const handleThemeReset = async () => {
    try {
      dispatch(setLoadCompanyLogo(true));
      setLoadingMessage("Resetting theme settings");
      setIsLoading(true);

      if (auth.companyTheme.MediaId) {
        await DeleteMedia(auth.companyTheme.MediaId);
      }

      const responseData: ResponseObject<CompanyTheme> =
        await companyService.resetTheme(dispatch);

      dispatch(setCompanyTheme(responseData.Result));
      dispatch(setPresignedCompanyLogoUrl(""));
    } finally {
      dispatch(setLoadCompanyLogo(false));
      setIsLoading(false);
      setLoadingMessage("");
    }
  };

  useEffect(() => {
    fetchCompanyTheme();
  }, [auth.companyTheme.Media]);

  return (
    <>
      {isLoading && (
        <Loader
          isLoadingScreen
          loadingText={loadingMessage !== "" ? loadingMessage : undefined}
        />
      )}
      <ThemeWrapper>
        <article>
          <Typography weight="bold">
            Personalize SWRM for You and Your Organization - Choose Your Theme
            Color & Upload Your Company Logo!
          </Typography>
        </article>

        <FlexContainer alignItems="center" gap="1rem" width="100%">
          <ColorPickerWrapper>
            <ColorPicker color={color} onChange={setColor} />
            <ScaledButtonWrapper buttonMargin="0.3rem">
              <button type="submit" onClick={handleThemeColorChange}>
                Save Theme Color
              </button>
            </ScaledButtonWrapper>
          </ColorPickerWrapper>

          <CompanyLogoWrapper>
            <Image
              src={auth.presignedCompanyLogoUrl || SwrmNavLogo}
              alt="Company Logo"
              handleOnError={handleCompanyLogoImageOnError}
              objectFit="contain"
            />
            <div>
              <StyledMdEdit onClick={handleLogoEditClick} />

              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
            </div>
          </CompanyLogoWrapper>
        </FlexContainer>

        <ScaledButtonWrapper buttonMargin="0.3rem">
          <button type="submit" onClick={handleThemeReset}>
            Reset to Default
          </button>
        </ScaledButtonWrapper>
      </ThemeWrapper>
    </>
  );
};

export default Themes;
