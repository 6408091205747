import { GroupBase, StylesConfig } from "react-select";
import styled, { DefaultTheme } from "styled-components";
import { FilterOption } from "./FilterDropdown";

export const FilterDropdownsWrapper = styled.div`
  display: flex;
  gap: 12px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  flex-wrap: wrap;
`;

export const FilterDropdownWrapper = styled.div<{ margin?: string }>`
  margin: ${({ margin }) => (margin ? margin : 0)};
`;

// Select component generation method from react-select package styling
export const filterDropdownStyle = (
  theme: DefaultTheme,
): StylesConfig<FilterOption, false, GroupBase<FilterOption>> => ({
  control: (provided: any, state: any) => ({
    ...provided,
    height: "2.25rem",
    width: "10rem",
    backgroundColor: theme.colors["black-100"],
    border: theme.border["primary-sm"],
    borderRadius: "5px",
    fontSize: theme.fontSizes.sm,
    boxShadow: "none",
    cursor: "pointer",

    "&:hover": {
      borderColor: theme.colors.secondary,
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    backgroundColor: theme.colors["black-100"],
    border: theme.border["primary-sm"],
    borderRadius: "5px",
    outline: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#FFF",
    cursor: "pointer",
    backgroundColor: state.isSelected && theme.colors.primary,

    "&:hover": {
      backgroundColor: theme.colors.primary,
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
});

export const filterDropdownStyleFull = (
  theme: DefaultTheme,
): StylesConfig<FilterOption, false, GroupBase<FilterOption>> => ({
  control: (provided: any, state: any) => ({
    ...provided,
    height: "2.25rem",
    backgroundColor: theme.colors["black-100"],
    border: theme.border["primary-sm"],
    borderRadius: "5px",
    fontSize: theme.fontSizes.sm,
    boxShadow: "none",
    cursor: "pointer",

    "&:hover": {
      borderColor: theme.colors.secondary,
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    backgroundColor: theme.colors["black-100"],
    border: theme.border["primary-sm"],
    borderRadius: "5px",
    outline: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#FFF",
    cursor: "pointer",
    backgroundColor: state.isSelected && theme.colors.primary,

    "&:hover": {
      backgroundColor: theme.colors.primary,
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
});
