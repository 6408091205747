import { useEffect, useState } from "react";
import { Container } from "../components/container/Container";
import Loader from "../components/loader/Loader";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  activeNavLink,
  resetNavLink,
} from "../components/nav/side-nav/sideNavBarSlice";
import { useNavigate } from "react-router-dom";
import authService from "../services/authentication/auth.service";
import { Auth0User } from "../interfaces/authentication/UserFromToken";
import { useAuth0 } from "@auth0/auth0-react";
import { ResponseObject } from "../interfaces/response/Response";
import { setRouteOption } from "../store/slices/routes/routesSlice";
import { CompanyReturnPayload } from "../services/company/company.service";
import {
  setAccessToken,
  setCompany,
  setHasSignedInPreviously,
  setIsSigningUp,
  setLoadCompanyLogo,
  userLogout,
} from "../store/slices/auth/authSlice";
import { RootState } from "../store/store";
import { integrationService } from "../services/integration/integration.service";
import { IntegrationCompany } from "../store/slices/integration/integrationSlice";

const RouterLoadingScreen = () => {
  // State variables
  const [authProcessDone, setAuthProcessDone] = useState(false);

  // Hooks
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state: RootState) => state.persisted.auth);
  const tokens = useAppSelector(
    (state: RootState) => state.persisted.auth.tokens,
  );
  const navigate = useNavigate();
  const { user, logout } = useAuth0();
  const isActiveSession = sessionStorage.getItem("isActiveSession");

  // Function to check authentication setup
  const checkAuthentication = async () => {
    if (tokens?.accessToken) {
      processAuthentication(tokens.accessToken);
    } else {
      getAccessTokenSilently().then((token) => {
        authService.setAuthTokenToHeader(token);
        dispatch(setAccessToken(token));
        processAuthentication(token);
      });
    }
  };

  // Function to start user authentication process
  const processAuthentication = async (accessToken: string) => {
    const verifyUserResponse: ResponseObject<string> | null =
      await verifyUser(accessToken);
    // Error flow with auto logout
    if (verifyUserResponse == null) {
      setTimeout(() => {
        logout();
        dispatch(userLogout());
        dispatch(resetNavLink());
        dispatch(setRouteOption(0));
      }, 2000);

      return;
    }

    // Check if user needs to be registered
    if (verifyUserResponse !== null && verifyUserResponse.ErrorCode === 404) {
      const registerUserResponse: ResponseObject<string> | null =
        await registerUser(accessToken);
      if (registerUserResponse !== null && !registerUserResponse.IsError) {
        authenticateUser(accessToken);
      }
    }

    if (verifyUserResponse !== null && !verifyUserResponse.IsError)
      authenticateUser(accessToken);
  };

  // Function to verify user
  const verifyUser = async (accessToken: string) => {
    const verifyResponse: ResponseObject<string> | null =
      await authService.verifyInSystem(user?.sub!, accessToken, dispatch);

    return verifyResponse;
  };

  // Function to register user
  const registerUser = async (accessToken: string) => {
    // Extract user data from Auth0 user object
    const auth0User: Auth0User = {
      Sub: user?.sub!,
      Email: user?.email!,
      Nickname: user?.nickname!,
      Picture: user?.picture!,
    };

    const registerResponse: ResponseObject<string> | null =
      await authService.registerToSystem(auth0User, accessToken, dispatch);

    return registerResponse;
  };

  // Function to authenticate user
  const authenticateUser = async (accessToken: string) => {
    // Extract user data from Auth0 user object
    const auth0User: Auth0User = {
      Sub: user?.sub!,
      Email: user?.email!,
      Nickname: user?.nickname!,
      Picture: user?.picture!,
    };

    // Authenticate user using authService
    const authResponse: ResponseObject<CompanyReturnPayload | string> | null =
      await authService.authenticateToSystem(auth0User, accessToken, dispatch);

    // Handle authentication response
    // Error flow and auto logout
    if (authResponse == null) {
      setTimeout(() => {
        logout();
        dispatch(userLogout());
        dispatch(resetNavLink());
        dispatch(setRouteOption(0));
      }, 2000);

      return;
    }

    // Sign up flow
    if (authResponse.ErrorCode == 404) {
      // Redirect user to get started if no role or user company
      redirectUser(true);
      setAuthProcessDone(true);
    }

    // Sign in flow
    if (
      authResponse.ErrorCode == 200 &&
      typeof authResponse.Result !== "string"
    ) {
      // Set company in Redux store and redirect user
      let integrationData: IntegrationCompany[] = [];

      // Fetch Integrations
      if (
        authResponse.Result.Company.IntegratedWith !== "" &&
        authResponse.Result.Company.IntegratedWith !== null
      ) {
        const response = await dispatch(
          integrationService.getIntegrations(),
        ).unwrap();
        if (response) integrationData.push(response.Result);
      }

      dispatch(setCompany(authResponse.Result));
      redirectUser(false);
      setAuthProcessDone(true);
    }
  };

  // Function to redirect user to Get Started page
  const redirectUser = async (redirectToGetStarted: boolean) => {
    // Set sign-in previously status and session
    dispatch(setHasSignedInPreviously(true));
    sessionStorage.setItem("isActiveSession", "true");

    // Redirect user based on status
    if (redirectToGetStarted) {
      // Redirect to get started page
      dispatch(setIsSigningUp(true));
      dispatch(setRouteOption(1));
      navigate("/getStarted");
    } else {
      // Set Initial Company Logo Load Boolean
      dispatch(setLoadCompanyLogo(true));

      // Redirect to dashboard after checking headers
      const headersCheck = setTimeout(() => {
        if (axios.defaults.headers.common["BSD-Company"] !== undefined) {
          clearTimeout(headersCheck);
          dispatch(setRouteOption(2));
          dispatch(activeNavLink(1));
          navigate("/dashboard");
        }
      }, 2000);
    }
  };

  // useEffect hook to trigger authentication process
  useEffect(() => {
    // Check local and session storage for authentication status
    const persistRoot = JSON.parse(localStorage.getItem("persist:root")!);
    const auth = JSON.parse(persistRoot.auth);
    // Authenticate user if necessary
    if (
      (!authProcessDone &&
        !auth.hasSignedInPreviously &&
        isActiveSession === null) ||
      auth.isSigningUp
    ) {
      checkAuthentication();
    }
  }, []);

  return (
    <Container>
      {/* Display loader while authentication is in progress */}
      <Loader loadingText="Getting things ready for you..." isLoadingScreen />
    </Container>
  );
};

export default RouterLoadingScreen;
