import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type Nullable<T> = T | null;
export interface FetchParamsForDevices {
  SearchedName: string;
  DeviceName: string;
  DeviceType: string;
  Deployed: boolean | null;
  GroupName: string;
  OrderByDesc: boolean;
  AiModelId: number;
}

export interface FetchParamsForCaptures {
  highConfidence: boolean | null;
  mediaType: string | null;
  archive: boolean;
  locationId: number;
  deviceId: string;
  modelId: number;
  ascending: boolean;
}
export interface ExportParamsForCaptures {
  mediaType: string | null;
  archive: boolean;
  locationId: number;
  deviceId: string;
  modelId: number;
  highConfidence: boolean | null;
}

export interface FetchParamsForLocations {
  name: string;
  // locationId: String;
  locationTypeId: number;
  enabled: boolean;
  // groupName: string;
  orderbydesc: boolean;
}

export interface FetchParamsForAIModels {
  dateCreated: string;
  activeDevice: string;
  orderByDesc: boolean;
}

interface SearchParams {
  DeviceSearchParams: FetchParamsForDevices;
  LocationSearchParams: FetchParamsForLocations;
  CaptureSearchParams: FetchParamsForCaptures;
  AIModelSearchParams: FetchParamsForAIModels;
}

export interface FilterParam {
  paramName: string;
  paramValue: string | boolean | number;
}

const initialState: SearchParams = {
  DeviceSearchParams: {
    SearchedName: "",
    DeviceName: "",
    DeviceType: "",
    Deployed: true,
    GroupName: "",
    OrderByDesc: false,
    AiModelId: 0,
  },
  CaptureSearchParams: {
    highConfidence: null,
    mediaType: "image",
    archive: false,
    locationId: 0,
    deviceId: "",
    modelId: 0,
    ascending: true,
  },
  LocationSearchParams: {
    name: "",
    // locationId: "",
    locationTypeId: 0,
    enabled: true,
    // groupName: string;
    orderbydesc: false,
  },
  AIModelSearchParams: {
    dateCreated: "",
    activeDevice: "",
    orderByDesc: false,
  },
};

export const fetchParametersSlice = createSlice({
  name: "fetchParameters",
  initialState,
  reducers: {
    // Set Device Parameters
    setDeviceParameters: (
      state,
      action: PayloadAction<FetchParamsForDevices>,
    ) => {
      state.DeviceSearchParams = { ...action.payload };
    },

    // Set Capture Parameters
    setCaptureParameters: (
      state,
      action: PayloadAction<FetchParamsForCaptures>,
    ) => {
      state.CaptureSearchParams = { ...action.payload };
    },

    // Set Location Parameters
    setLocationParameters: (
      state,
      action: PayloadAction<FetchParamsForLocations>,
    ) => {
      state.LocationSearchParams = { ...action.payload };
    },

    // Set AI Model Parameters
    setAIModelParameters: (
      state,
      action: PayloadAction<FetchParamsForAIModels>,
    ) => {
      state.AIModelSearchParams = { ...action.payload };
    },

    // Update Capture Parameter
    updateCaptureParameter: (state, action: PayloadAction<FilterParam>) => {
      const { paramName, paramValue } = action.payload;
      state.CaptureSearchParams = {
        ...state.CaptureSearchParams,
        [paramName]: paramValue,
      };
    },
    // reset capture parameter when tab change
    resetCaptureParameter: (
      state,
      action: PayloadAction<"image" | "video" | null>,
    ) => {
      state.CaptureSearchParams = {
        ...initialState.CaptureSearchParams,
        mediaType: action.payload,
      };
    },

    // Update Location Parameter
    updateLocationParameter: (state, action: PayloadAction<FilterParam>) => {
      const { paramName, paramValue } = action.payload;
      state.LocationSearchParams = {
        ...state.LocationSearchParams,
        [paramName]: paramValue,
      };
    },

    // Update AI Model Parameter
    updateAIModelParameter: (state, action: PayloadAction<FilterParam>) => {
      const { paramName, paramValue } = action.payload;
      state.AIModelSearchParams = {
        ...state.AIModelSearchParams,
        [paramName]: paramValue,
      };
    },
    resetSearchParams: (state, action: PayloadAction<{ page: string }>) => {
      //Reset the search params according to the page(DeviceManager/Location/Captures), setup other pages if required
      const page = action.payload.page;
      switch (page) {
        case "DeviceManager":
          state.DeviceSearchParams = initialState.DeviceSearchParams;
          break;
        case "Locations":
          state.LocationSearchParams = initialState.LocationSearchParams;
          break;
        case "Captures":
          state.CaptureSearchParams = initialState.CaptureSearchParams;
          break;
        case "AIModels":
          state.AIModelSearchParams = initialState.AIModelSearchParams;
          break;
        case "All":
          return initialState;
        default:
          break;
      }
    },
    clearAllFilters: () => {
      return initialState;
    },
  },
});

export const {
  setDeviceParameters,
  setLocationParameters,
  setAIModelParameters,
  clearAllFilters,
  resetSearchParams,
  setCaptureParameters,
  updateCaptureParameter,
  resetCaptureParameter,
  updateLocationParameter,
  updateAIModelParameter,
} = fetchParametersSlice.actions;

export default fetchParametersSlice.reducer;
