import React, { ChangeEvent } from "react";
import { SecurityWrapper, TableButtonWrapper } from "./Security.style";
import { InputButtonWrapper, TextWrapper } from "./Account.style";
import { Typography } from "../../typrography/Typography";
import HelpDropdownList from "../../dropdown/help-dropdown-list/HelpDropdownList";
import securitySettingDropdowns from "../../../data/securitySettingDropdowns";
import { Formik } from "formik";
import * as Yup from "yup";
import { StyledFormikForm } from "../ai-model-manager/Upload.style";
import { FormWrapper } from "../../modals/CustomModal.style";
import Input from "../../input/Input";
import { Button } from "../../button/Button";
import { TableWrapper } from "../../tables/Table.style";
import TokenGenerationTable from "../../tables/TokenGenerationTable";
import { ButtonWrapper } from "../../tables/table-button/TableButton.style";
import { GoKey } from "react-icons/go";
import tokenGenerationTable from "../../../data/tokenGenerationTable";
import { DefaultThemeColor } from "../../../app/Theme";
import { DefaultTheme, useTheme } from "styled-components";

type Props = {};

interface FormValues {}
const validationSchema = Yup.object().shape({
  // name: Yup.string()
  //   .min(2, "Name must be at least 2 characters")
  //   .max(50, "Name must be up to 50 characters")
  //   .required("Name is required"),
});
const Security = (props: Props) => {
  const theme: DefaultTheme = useTheme();
  const securitySettings = securitySettingDropdowns.filter(
    (item) => item.menuType === "securitySettings",
  );
  const recoverySettings = securitySettingDropdowns.filter(
    (item) => item.menuType === "recoverySettings",
  );
  const [email, setEmail] = React.useState("Loremipsum@gmail.com");
  const [phoneNum, setPhoneNum] = React.useState("12047777777");
  const initialValues = {
    email: "",
    phone: "",
  };
  const columns = ["Secure Key", "Created", "Last Used", ""];
  const handelSubmit = (values: FormValues, { setSubmitting }: any) => {};
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePhoneNumChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNum(event.target.value);
  };
  const handleItemCheckChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {};
  return (
    <SecurityWrapper>
      <TextWrapper>
        <Typography
          direction="flex-start"
          variant="2xl"
          style={{ padding: "0.5vw 0" }}
        >
          Security Settings
        </Typography>
        <Typography
          direction="flex-start"
          variant="lg"
          color="primary"
          style={{ padding: "0.5vw 0" }}
        >
          Sets your accounts security preferences and gives access to tools like
          Two-Factor Authentication and account Recovery Questions.
        </Typography>
      </TextWrapper>
      <HelpDropdownList
        data={securitySettings}
        width="100%"
        padding="0"
        margin="0 0 1rem"
      />
      <TextWrapper>
        <Typography
          direction="flex-start"
          variant="2xl"
          style={{ padding: "0.5vw 0" }}
        >
          Recovery Settings
        </Typography>
        <Typography
          direction="flex-start"
          variant="lg"
          color="primary"
          style={{ padding: "0.5vw 0" }}
        >
          Sets your accounts security preferences and gives access to tools like
          Two-Factor Authentication and account Recovery Questions.
        </Typography>
      </TextWrapper>
      <HelpDropdownList
        data={recoverySettings}
        width="100%"
        padding="0"
        margin="0 0 1rem"
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handelSubmit}
      >
        {({ isSubmitting }: any) => (
          <StyledFormikForm>
            <FormWrapper>
              <InputButtonWrapper>
                <Input
                  name={"email"}
                  placeHolder={"Recovery Email"}
                  label={"Recovery Email"}
                  width={"100%"}
                  height="5.3vh"
                  padding="10px 0 0"
                  fontSize="1vw"
                  inputWrapperWidth="100%"
                  value={email}
                  type="email"
                  onChange={handleEmailChange}
                />
                <Button
                  borderRadius="0"
                  height="5.3vh"
                  hoverColor={DefaultThemeColor.secondary}
                  style={{ position: "absolute", bottom: "5px", right: "0" }}
                >
                  Change
                </Button>
              </InputButtonWrapper>
              <InputButtonWrapper>
                <Input
                  name={"phoneNumber"}
                  placeHolder={"Recovery Phone Number"}
                  label={"Recovery Phone Number"}
                  width={"100%"}
                  height="5.3vh"
                  padding="10px 0 0"
                  fontSize="1vw"
                  inputWrapperWidth="100%"
                  value={phoneNum}
                  onChange={handlePhoneNumChange}
                  type="number"
                />
                <Button
                  borderRadius="0"
                  height="5.3vh"
                  hoverColor={DefaultThemeColor.secondary}
                  style={{ position: "absolute", bottom: "5px", right: "0" }}
                >
                  Change
                </Button>
              </InputButtonWrapper>
            </FormWrapper>
          </StyledFormikForm>
        )}
      </Formik>
      <TableButtonWrapper>
        <TableWrapper>
          <TokenGenerationTable
            columns={columns}
            data={tokenGenerationTable}
            handleItemCheckChange={handleItemCheckChange}
            width={"60%"}
            trBorder={theme.border["primary-sm"]}
            tdColor={theme.colors.primary}
          />
        </TableWrapper>
        <ButtonWrapper>
          <Button scaled={true} borderRadius="3px" size="xs">
            <GoKey size={"22px"} />
            Add Key
          </Button>
        </ButtonWrapper>
      </TableButtonWrapper>
    </SecurityWrapper>
  );
};

export default Security;
