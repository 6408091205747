import styled from "styled-components";

export const SideNavBarContainer = styled.div`
  width: 15vw;
  height: 100%;
  background: ${(props) => props.theme.colors["black-200"]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: solid 4px ${(props) => props.theme.colors.primary};
  position: fixed;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 20vw;
  }
  & img {
    width: 100%;
    padding: 1rem;
  }

  & nav.side-nav-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & nav.side-nav-bottom {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: 10px;
  }

  & a,
  & button {
    display: flex;
    align-items: center;
    line-height: 1.5;
    text-decoration: none;
    color: #ffffff;
    padding: 10px;
  }

  & button {
    width: 100%;
    background: ${(props) => props.theme.colors["black-200"]};
    border: none;
    cursor: pointer;
  }

  & button:hover {
    color: ${(props) => props.theme.colors["black-200"]};
    background: ${(props) => props.theme.colors.primary};

    //Change svg color when the button is hover
    & .logo path {
      fill: ${(props) => props.theme.colors["black-200"]};
    }
  }

  & ul {
    width: 100%;
  }

  & .active-link {
    background: ${(props) => props.theme.colors.primary};
  }

  & .active-link a {
    color: #111817;
  }

  & nav li:hover {
    background: ${(props) => props.theme.colors.primary};
  }

  & nav li:hover a {
    color: ${(props) => props.theme.colors["black-200"]};
  }

  & span {
    padding: 0 10px;
    font-size: ${(props) => props.theme.fontSizes.md};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .message-num {
    height: 24px;
    width: 24px;
    border-radius: 24px;
    line-height: 1.5;
    background: #d64550;
    display: flex;
    justify-content: center;
  }
`;
