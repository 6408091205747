import React from "react";
import { Tooltip } from "react-tooltip";
import { StyledHiOutlineDuplicate } from "../tables/Table.style";
import { CustomIconButton } from "../button/icon-button/CustomIconButton";

interface DuplicateIconProps {
  handleDuplicateOnClick: () => void;
  disabled?: boolean;
}

const DuplicateIcon: React.FC<DuplicateIconProps> = ({
  handleDuplicateOnClick,
  disabled = false,
}) => {
  return (
    <CustomIconButton disabled={disabled} onClick={handleDuplicateOnClick}>
      <StyledHiOutlineDuplicate
        data-tooltip-id="duplicate-tooltip"
        data-tooltip-content="Duplicate"
        data-tooltip-place="top"
      />
      <Tooltip id="duplicate-tooltip" />
    </CustomIconButton>
  );
};

export default DuplicateIcon;
