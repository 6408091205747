export const formatJSONData = (jsonString: string) => {
  if (jsonString != null) {
    let data = JSON.parse(jsonString);
    let formattedData = data
      .map((item: any) => {
        return Object.entries(item)
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              // If the value is an array of arrays (like position)
              if (Array.isArray(value[0])) {
                return `${key}: ${value
                  .map((v) => `(${v.join(", ")})`)
                  .join(", ")}`;
              }
              // If it's a simple array
              return `${key}: ${value.join(", ")}`;
            } else if (typeof value === "object" && value !== null) {
              // If the value is an object (like movement)
              return `${key}: ${Object.entries(value)
                .map(([k, v]) => `${k}: ${v}`)
                .join(", ")}`;
            }
            return `${key}: ${value}`;
          })
          .join(",\n");
      })
      .join("\n");
    return formattedData;
  } else {
    return "";
  }
};
