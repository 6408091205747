import styled, { DefaultTheme } from "styled-components";
import Modal from "styled-react-modal";
import { Typography } from "../typrography/Typography";

export const StyledModal = Modal.styled`
  position: relative;
  width: 85vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props: any) => props.theme.colors["black-100"]};
  border-radius: 5px;
  border: ${(props: any) => props.theme.border["primary-sm"]};
  transition : all 0.3s ease-in-out;
  padding: 2rem;
`;

export const FilterButtonWrapper = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  & button {
    height: 2.5rem;
    padding: 0 0.5em;
    border-radius: 26px;
    background: ${(props) => props.theme.colors.primary};
    color: #111817;
    font-weight: 900;
    font-size: ${(props) => props.theme.fontSizes.md};
    transition: transform 0.2s;
    border: ${(props) => props.theme.border["primary-sm"]};
    cursor: pointer;
  }

  & button:hover {
    transform: scale(1.1);
  }
`;

export const StyledWrapper = styled.div`
  padding: 2em;
  width: 100%;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-bottom: 70px;
  width: 100%;

  @media (min-width: 480px) {
    width: 90%;
  }

  @media (min-width: 768px) {
    width: 95%;
  }

  @media (min-width: 1024px) {
    width: 90%;
  }
`;

export interface FlexContainerProps {
  justifyContent?: string;
  width?: string;
}

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  width: ${(props) => props.width || "auto"};
`;

export const Heading = styled(Typography)`
  && {
    font-size: 3.5rem;
    text-align: left;
  }
`;

export const ResponsiveFlexContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  width: 100%;
  flex: 1 1 0%; // This ensures both containers grow and shrink equally

  @media (min-width: 768px) {
    width: 48%; // Ensure this is the same for both
  }

  @media (min-width: 1024px) {
    width: 48%; // Ensure this is the same for both
  }
`;

export const ImageCardsWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  gap: 1rem;

  &::-webkit-scrollbar {
    display: block;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #444444;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }
`;

export const StyledLabel = styled.label`
  outline: none;
  font-size: ${(props) => props.theme.fontSizes.xs};
  background-color: ${(props) => props.theme.colors["black-100"]};

  //set the background color and font color for browser autofill feature.
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #020a01 inset !important;
    -webkit-text-fill-color: white !important;
  }
  font-weight: bold;
  padding: 8px;
  padding-left: 12px;
  margin: 4px 0;
  border-radius: 4px;
  width: 100%;
  /* height: 40px; */
  height: 2.25rem;
  background-color: ${(props) => props.theme.colors["black-100"]};
  border: ${(props) => props.theme.border["primary-sm"]};
  color: ${(props) => props.theme.colors["grey-500"]};
  &::placeholder {
    color: ${(props) => props.theme.colors["grey-800"]};
  }

  &:hover {
    border: ${(props) => props.theme.border["secondary-sm"]};
  }
`;
