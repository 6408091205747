import { FieldHelperProps, useField } from "formik";
import { SelectErrorMsg } from "./SelectDropdown.style";
import Select from "react-select";
import { Country } from "country-state-city";
import { Option } from "../../interfaces/dropdownOption/Option";
import { DefaultTheme, useTheme } from "styled-components";

interface CountryCodeDropdownProps {
  name: string;
}

export const countryCodeDropdownStyle = (theme: DefaultTheme) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    height: "2.25rem",
    backgroundColor: theme.colors["black-100"],
    border: theme.border["primary-sm"],
    borderRadius: "5px",
    fontSize: theme.fontSizes.sm,
    boxShadow: "none",
    cursor: "pointer",

    "&:hover": {
      borderColor: theme.colors.secondary,
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    backgroundColor: theme.colors["black-100"],
    border: theme.border["primary-sm"],
    borderRadius: "5px",
    outline: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#FFF",
    cursor: "pointer",
    backgroundColor: state.isSelected && theme.colors.primary,

    "&:hover": {
      backgroundColor: theme.colors.primary,
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#B5B5B5",
  }),
});

const CountryCodeDropdown = ({ name }: CountryCodeDropdownProps) => {
  const theme: DefaultTheme = useTheme();
  const [field, meta, helpers] = useField(name);

  const handleOptionChange = (
    helpers: FieldHelperProps<Option | null>,
    option: Option | null,
  ) => {
    helpers.setValue(option);
  };

  const handleOptionsBlur = (
    helpers: FieldHelperProps<Option | null>,
    value: Option | null,
    fieldName: string,
  ) => {
    if (!value) {
      helpers.setTouched(true);
      helpers.setError(`${fieldName} is required`);
    }
  };

  const options = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.isoCode,
  }));

  return (
    <>
      <Select
        name={name}
        options={options}
        value={field.value}
        onChange={(item: Option | null) => handleOptionChange(helpers, item)}
        onBlur={() => handleOptionsBlur(helpers, field.value, "Country")}
        placeholder="Country Code"
        menuPosition="fixed"
        styles={countryCodeDropdownStyle(theme)}
      />
      {meta.touched && meta.error && (
        <SelectErrorMsg>{meta.error}</SelectErrorMsg>
      )}
    </>
  );
};

export default CountryCodeDropdown;
