import React from "react";
import { SubTitle } from "../title/Titles.styles";
import { IconSection } from "../sort-by/SortBy.style";
import { ThumbnailSizeItem } from "./thumbnailSizeSlice";
import { ThumbnailSizeIconWrapper } from "./ThumbnailSize.style";
import { DefaultTheme, useTheme } from "styled-components";

export interface ThumbnailSizeProps {
  thumbnailSizeItems: ThumbnailSizeItem[];
  handleChangeThumbnailViewSize: (id: number) => void;
}

const ThumbnailSize: React.FC<ThumbnailSizeProps> = ({
  thumbnailSizeItems,
  handleChangeThumbnailViewSize,
}) => {
  const theme: DefaultTheme = useTheme();
  return (
    <>
      <SubTitle>Thumbnail Size</SubTitle>
      <IconSection>
        {thumbnailSizeItems.map((item) => (
          <ThumbnailSizeIconWrapper
            key={item.id}
            isActive={item.isActive}
            onClick={() => handleChangeThumbnailViewSize(item.id)}
          >
            <item.icon
              size="1.25rem"
              color={item.isActive ? theme.colors.primary : ""}
            />
          </ThumbnailSizeIconWrapper>
        ))}
      </IconSection>
    </>
  );
};

export default ThumbnailSize;
