import React from "react";
import SearchInput from "../../input/SearchInput";
import ApiDropdownList from "../../dropdown/api-dropdown-list/ApiDropdownList";
import integrationApiList from "../../../data/integrationApiList";

interface DocumentationProps {}

const Documentation = (props: DocumentationProps) => {
  const handleSearchByName = () => {
    // To be implemented later.
  };
  return (
    <>
      <SearchInput
        label={""}
        placeHolder={"Search Documentation"}
        searchIcon={true}
        handleSearch={handleSearchByName}
      ></SearchInput>
      {integrationApiList.map((item, index) => (
        <ApiDropdownList key={index} apiData={item} />
      ))}
    </>
  );
};

export default Documentation;
