import { useEffect, useState } from "react";
import { Theme } from "../../app/Theme";
import SideNavBar from "../../components/nav/side-nav/SideNavBar";
import { ContentWrapper } from "../../components/content-wrapper/ContentWrapper";
import TopNavBar from "../../components/nav/top-nav/TopNavBar";
import Titles from "../../components/title/Titles";
import { Tabs } from "../../components/tab/Tabs";
import Account from "../../components/tab/account-settings/Account";
import Security from "../../components/tab/account-settings/Security";
import Themes from "../../components/tab/account-settings/Theme";
import Billing from "../../components/tab/account-settings/Billing";
import { ITab } from "../../interfaces/tabs/pageTabs";
import Users from "../../components/tab/account-settings/Users";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import Data from "../../components/tab/account-settings/Data";

interface HelpProps {}

const tabs: ITab[] = [
  {
    label: "Account",
    index: 1,
    Component: Account,
  },
  {
    label: "Security",
    index: 2,
    Component: Security,
    disabled: true,
  },
  {
    label: "Theme",
    index: 3,
    Component: Themes,
  },
  {
    label: "Billing",
    index: 4,
    Component: Billing,
  },
  {
    label: "Users",
    index: 5,
    Component: Users,
  },
  /* Commented out for the time because of Soil Scout removal */
  // {
  //   label: "Integrations",
  //   index: 5,
  //   Component: Integration,
  // },
  {
    label: "Data",
    index: 6,
    Component: Data,
  },
];

const Help = (props: HelpProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const userRole = useAppSelector(
    (state: RootState) => state.persisted.auth.userRole,
  );
  const integratedWith = useAppSelector(
    (state: RootState) => state.persisted.auth.company.IntegratedWith,
  );
  const handleEvent = (e: number) => {
    setSelectedTab(e);
  };
  const filteredTab = () => {
    switch (userRole.toLocaleLowerCase()) {
      case "owner":
        if (integratedWith === null || integratedWith === "") {
          return tabs.filter((t) => t.label !== "Integrations");
        }

        return tabs;
      default:
        return tabs.filter((t) => t.label === "Account");
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabName = urlParams.get("tab");
    if (tabName === "billing") {
      setSelectedTab(tabs[2].index);
    }
  }, []);
  return (
    <>
      <Theme>
        <SideNavBar />
        <ContentWrapper>
          <TopNavBar />
          <Titles
            heading="Account Settings"
            subheading="Customize your account settings and preferences"
          />
          <Tabs
            selectedTab={selectedTab}
            onClick={handleEvent}
            tabs={filteredTab()}
            tabsDirection="column"
            customTabsDisplay="flex"
            borderDirection="row"
            tabWidth="10vw"
            fontSize="1.25rem"
            tabPanelPadding="0"
            padding="0.5rem"
          />
        </ContentWrapper>
      </Theme>
    </>
  );
};

export default Help;
