import { Container } from "../../components/container/Container";
import PageDescription from "../../sections/login/PageDescription";

const Login = () => {
  const apiUrl = process.env.REACT_APP_ENV;

  return (
    <div style={{ width: "100%" }}>
      <Container>
        <PageDescription></PageDescription>
      </Container>
    </div>
  );
};

export default Login;
