import { UserRoles } from "../interfaces/authentication/User";

export const isAuthorizedUser = (
  userRoleId: number,
  additionalAuthorizedRoleId?: number,
): boolean => {
  return (
    [UserRoles.Root, UserRoles.Owner].includes(userRoleId) ||
    userRoleId === additionalAuthorizedRoleId
  );
};

export const getUserRoleId = (roleName: string): number => {
  return UserRoles[roleName as keyof typeof UserRoles];
};
