import React, { Fragment, useState } from "react";
import { SwrmDevices } from "../../../../interfaces/device/SwrmDevice";
import DeviceCard from "../../../../pages/deviceManager/DeviceCard";
import { ImageCarousel } from "../../../image-carousel/ImageCarousel";
import {
  setActiveDeviceImageCard,
  setSelectedDevice,
} from "../../../../store/slices/devices/devicesSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { InfoCardDeviceManager } from "../../../../pages/deviceManager/manage/info-card-device-manager/InfoCardDeviceManager";
import DeviceManagerTabs from "../../../../pages/deviceManager/DeviceManagerTabs";
interface Props {
  deviceList: SwrmDevices[];
}
export const DeviceList = ({ deviceList }: Props) => {
  const dispatch = useAppDispatch();
  const activeDeviceId = useAppSelector(
    (state) => state.devices.activeDeviceId,
  );
  const [currentIndex, setCurrentIndex] = useState<number>(
    deviceList.findIndex((l: SwrmDevices) => l.Id === activeDeviceId),
  );
  const handleImageIndexChange = (index: number, Id: string) => {
    setCurrentIndex(index);
    if (Id === "") {
      Id = deviceList[index].Id || "";
    }
    dispatch(setActiveDeviceImageCard(Id));
    dispatch(setSelectedDevice(deviceList[index]));
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      handleImageIndexChange(currentIndex - 1, "");
    }
  };

  const handleNext = () => {
    if (currentIndex < deviceList.length - 1) {
      handleImageIndexChange(currentIndex + 1, "");
    }
  };

  return (
    <>
      <ImageCarousel currentIndex={currentIndex} dataLength={deviceList.length}>
        {deviceList.map((d: SwrmDevices, index: number) => (
          <DeviceCard
            key={d.Id}
            device={d}
            onClick={() => handleImageIndexChange(index, d.Id)}
          />
        ))}
      </ImageCarousel>
      {deviceList[currentIndex]?.Id ? (
        <Fragment key={deviceList[currentIndex]?.Id}>
          <InfoCardDeviceManager
            key={deviceList[currentIndex].Id}
            device={deviceList[currentIndex]}
            next={handleNext}
            prev={handlePrev}
          />
          <DeviceManagerTabs />
        </Fragment>
      ) : null}
    </>
  );
};
