import React from "react";
import ImageCard from "../../components/image-card/ImageCard";
import deviceImage2 from "../../assets/device2.png";
import { SwrmDevices } from "../../interfaces/device/SwrmDevice";
import { ControlButtons } from "../../components/device-manager-card/image-card/ImageCard.style";
import { Chip } from "../../components/chip/Chip";
import { ChipStyles } from "../../enums/chip/ChipEnums";
import { FlexContainer } from "../../components/flex-container/FlexContainer";
import { SecondaryHeader } from "../../components/image-card/ImageCard.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleXmark,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { DefaultTheme, useTheme } from "styled-components";

// Define props interface
interface CardProps {
  device: SwrmDevices;
  className?: string;
  onClick?: any;
}
export const getDeviceStatus = (upToDate: any, connectionStatus: any) => {
  return connectionStatus ? "Connected" : "Disconnected";
};

export const getDeviceWifiStatus = (wifiStatus: boolean) => {
  return wifiStatus ? "Connected" : "Disconnected";
};

// Define functional component
const DeviceCard: React.FC<CardProps> = ({ className, device, onClick }) => {
  const theme: DefaultTheme = useTheme();
  const renderDeviceStatus = () => {
    let status = getDeviceStatus(device.UpToDate, device.ConnectionStatus);
    return (
      <SecondaryHeader>
        {status}{" "}
        {
          <FontAwesomeIcon
            icon={
              status === "Connected" ? faCheckCircle
              : status === "Out of Date" ?
                faExclamationCircle
              : faCircleXmark
            }
            color={
              status === "Connected" ? "#80D39B"
              : status === "Out of Date" ?
                theme.colors.primary
              : "#D64550"
            }
          />
        }
      </SecondaryHeader>
    );
  };
  return (
    <ImageCard
      className={`device-card ${className || ""}`}
      image={device.Thumbnail || deviceImage2}
      title={device.Name || ""}
      subtitle={device.PiSerial}
      onClick={onClick}
    >
      <ControlButtons>
        <Chip
          children={
            device.Deployed ? ChipStyles.Deployed : ChipStyles.Undeployed
          }
          backgroundColor={device.Deployed ? "blue" : "grey"}
        />
      </ControlButtons>

      {/* card footer */}
      <FlexContainer width="100%" justifyContent="space-between">
        {renderDeviceStatus()}
      </FlexContainer>
    </ImageCard>
  );
};
export default DeviceCard;
