import React from "react";
import { CustomTypography } from "./Typography.styles";
import { DefaultTheme } from "styled-components";

type FontSizeVariant = keyof DefaultTheme["fontSizes"];
type Weight = keyof DefaultTheme["fontWeight"];

export interface TypographyProps {
  variant?: FontSizeVariant;
  color?: string;
  weight?: Weight;
  children: string | React.ReactNode | React.ReactNode[] | React.FC<{}> | any;
  display?: string;
  direction?: string;
  padding?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLElement>) => void;
  style?: any;
  disabled?: boolean;
}

export const Typography: React.FC<TypographyProps> = ({
  variant,
  color,
  children,
  weight,
  display,
  direction,
  padding,
  onClick,
  onMouseOver,
  style,
  disabled = false,
}) => {
  return (
    <CustomTypography
      variant={variant}
      color={color}
      weight={weight}
      display={display}
      direction={direction}
      padding={padding}
      onClick={onClick}
      onMouseOver={onMouseOver}
      style={style}
      disabled={disabled}
    >
      {children}
    </CustomTypography>
  );
};
