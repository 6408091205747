import { Typography } from "../../typrography/Typography";
import CustomModal from "../CustomModal";
import {
  DeleteContentWrapper,
  ModalWrapper,
  DeleteModalContent,
  ScaledButtonWrapper,
  HorizontalLine,
} from "../CustomModal.style";
import { useCallback, useEffect, useRef } from "react";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: (value: boolean) => void;
  onConfirm: () => void;
  headerTitle: string;
  confirmMsg: string;
  modalWidth?: string;
  modalHeight?: string;
  minWidth?: string;
  minHeight?: string;
  OkBtnText?: string;
  CancelBtnText?: string;
  loading?: boolean;
}

const ConfirmationModal = ({
  isOpen,
  onClose,
  setIsOpen,
  onConfirm,
  headerTitle,
  confirmMsg,
  modalWidth,
  modalHeight,
  minWidth,
  minHeight,
  OkBtnText = "Confirm",
  CancelBtnText = "Cancel",
  loading = false,
}: ConfirmationModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = () => setIsOpen(false);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.addEventListener("keydown", handleKeyPress);
      };
    }
  }, [handleKeyPress, isOpen]);

  return (
    <>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        width={modalWidth}
        height={modalHeight}
        minWidth={minWidth}
        minHeight={minHeight}
      >
        <DeleteContentWrapper>
          <Typography
            variant="lg"
            direction="flex-start"
            style={{ padding: "0 20px", fontWeight: "700" }}
          >
            {headerTitle}
          </Typography>
        </DeleteContentWrapper>
        <HorizontalLine />
        <DeleteContentWrapper>
          <Typography variant="lg" padding="0 20px">
            {confirmMsg}
          </Typography>
        </DeleteContentWrapper>
        {/* <HorizontalLine /> */}
        <ScaledButtonWrapper buttonMargin="0 0 15px">
          <button
            className="danger"
            disabled={loading}
            onClick={() => setIsOpen(false)}
          >
            {CancelBtnText}
          </button>
          <button onClick={onConfirm} disabled={loading}>
            {OkBtnText}
          </button>
        </ScaledButtonWrapper>
      </CustomModal>
    </>
  );
};

export default ConfirmationModal;
