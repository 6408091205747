import { formatJSONData } from "../../../utils/dynamicJsonUtils";
import { Typography } from "../../typrography/Typography";
import CustomModal from "../CustomModal";
import {
  DeleteContentWrapper,
  ModalWrapper,
  DeleteModalContent,
  ScaledButtonWrapper,
  HorizontalLine,
  BasicModalFormWrapper,
} from "../CustomModal.style";
import { useCallback, useEffect, useRef } from "react";

interface MiscInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: (value: boolean) => void;
  headerTitle: string;
  mainContent: string;
  modalWidth?: string;
  modalHeight?: string;
  minWidth?: string;
  minHeight?: string;
}

const MiscInfoModal = ({
  isOpen,
  onClose,
  setIsOpen,
  headerTitle,
  mainContent,
  modalWidth,
  modalHeight,
  minWidth,
  minHeight,
}: MiscInfoModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = () => setIsOpen(false);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.addEventListener("keydown", handleKeyPress);
      };
    }
  }, [handleKeyPress, isOpen]);

  return (
    <>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        width={modalWidth}
        height={modalHeight}
        minWidth={minWidth}
        minHeight={minHeight}
      >
        <DeleteContentWrapper>
          <Typography
            variant="lg"
            direction="flex-start"
            style={{ padding: "0 20px", fontWeight: "700" }}
          >
            {headerTitle}
          </Typography>
        </DeleteContentWrapper>
        <HorizontalLine />
        <BasicModalFormWrapper>
          <Typography variant="lg" padding="1rem 1.5rem">
            <pre>{formatJSONData(mainContent)}</pre>
          </Typography>
        </BasicModalFormWrapper>
        {/* <HorizontalLine /> */}
      </CustomModal>
    </>
  );
};

export default MiscInfoModal;
