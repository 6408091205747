import styled from "styled-components";

export const ThemeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2vw;
  width: 100%;
`;

export const ColorPickerWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.25rem;
`;

export const CompanyLogoWrapper = styled.button`
  background-color: transparent;
  position: relative;
  display: flex;
  height: 60%;
  width: 80%;
  border: none;
  cursor: pointer;
  & div {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: ${(props) => props.theme.colors["primary"]};
    bottom: 0;
    right: 5px;
    font-size: ${(props) => props.theme.fontSizes["xs"]};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & div:hover {
    background: ${(props) => props.theme.colors["green-100"]};
  }
`;
