import { useState } from "react";
import { useAppDispatch } from "../../../../store/hooks";
import { CustomIconButton } from "../../../../components/button/icon-button/CustomIconButton";
import ConfirmationModal from "../../../../components/modals/confirmation-modal/ConfirmationModal";
import { Typography } from "../../../../components/typrography/Typography";
import { IconWrapper } from "./DeviceName.style";
import PowerOffIcon from "../../../../components/icons/PowerOffIcon";
import deviceConfigService from "../../../../services/device/deviceConfig.service";

type Props = {
  deviceId: string;
  deviceName: string;
};

const DevicePowerOff = ({ deviceId, deviceName }: Props) => {
  const dispatch = useAppDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const handlePowerOffOnClick = () => {
    const msg = `Are you sure you'd like to power off ${deviceName || ""}?`;
    setConfirmMsg(msg);
    setIsConfirmModalOpen(true);
  };

  const handlePowerOffDevice = async () => {
    setLoading(true);
    await deviceConfigService.powerOffDevice(deviceId, dispatch);
    setIsConfirmModalOpen(false);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <CustomIconButton onClick={handlePowerOffOnClick}>
        <Typography variant="lg" color="white" weight="bold">
          <IconWrapper padding={"0.5rem"}>
            <PowerOffIcon size="1.25rem" />
          </IconWrapper>
        </Typography>
      </CustomIconButton>
      {/* Power Off Device Confirmation Modal */}
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={handlePowerOffDevice}
        headerTitle={"Power Off Device"}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        loading={loading}
      />
    </>
  );
};

export default DevicePowerOff;
