import axios, { AxiosError, AxiosResponse } from "axios";
import { config } from "process";
import { URLSearchParams } from "url";
import { GroupedHistoricalSensor } from "../../interfaces/sensorData/SensorData";
import { ResponseObject } from "../../interfaces/response/Response";
import {
  validateFetchErrorResult,
  validateFetchSuccessResult,
} from "../../utils/axiosUtils";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getLastScannedSensorData = async (
  locationId?: number | null,
  deviceId?: string,
) => {
  try {
    const response = await axios
      .get(
        `${API_URL}/Graph/LastScannedSensorData?locationId=${
          locationId || ""
        }&deviceId=${deviceId || ""}`,
      )
      .then(async (response) => {
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};
export const getHistoricalScannedSensorData = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  locationId?: number | null,
  deviceId?: string,
  timeline?: string,
) => {
  const response = await axios
    .get(
      `${API_URL}/Graph/HistoricalScannedSensorData?locationId=${
        locationId || ""
      }&deviceId=${deviceId || ""}&timeline=${timeline || "weekly"}`,
    )
    .then(
      async (
        response: AxiosResponse<ResponseObject<GroupedHistoricalSensor[]>>,
      ) => {
        // Validate Success Result
        const result = response.data;
        await validateFetchSuccessResult(response);

        return result;
      },
    )
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateFetchErrorResult(error, dispatch);
    });

  return response;
};

export const exportSensorData = async (
  locationId?: number | null,
  deviceId?: string,
) => {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}/Graph/ExportSensorDataToCsv?locationId=${
        locationId || ""
      }&deviceId=${deviceId || ""}`,
      responseType: "blob", // Set the response type to 'blob' to handle binary data
    }).then(async (response) => {
      return response.data;
    });
    return response;
  } catch (error: unknown) {
    return error;
  }
};
export const getDeviceConnectionData = async (deviceId: string) => {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}/Graph/ExportDeviceConnectionDataToCsv?deviceId=${deviceId}`,
      responseType: "blob", // Set the response type to 'blob' to handle binary data
    }).then(async (response) => {
      return response.data;
    });
    return response;
  } catch (error: unknown) {
    return error;
  }
};
const dashboardService = {
  getLastScannedSensorData,
  getWeeklyScannedSensorData: getHistoricalScannedSensorData,
  exportSensorData,
  getDeviceConnectionData,
};
export default dashboardService;
