import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const chargePayment = async (payload: any) => {
  try {
    const response = await axios
      .post(`${API_URL}/stripe/charge-customer`, payload)
      .then(async (response) => {
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

export const getSubscriptions = createAsyncThunk(
  "subscriptions/getSubscriptions",
  async () => {
    try {
      let response = await axios.get(`${API_URL}/StripeProducts`);
      return response.data;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const getCompanySubscriptions = createAsyncThunk(
  "subscriptions/getCompanySubscriptions",
  async () => {
    try {
      let response = await axios.get(`${API_URL}/Company/subscriptions`);
      return response.data;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const getLastPaymentCardDetails = createAsyncThunk(
  "subscriptions/getLastPaymentCardDetails",
  async (paymentMethodId: string) => {
    try {
      let response = await axios.get(
        `${API_URL}/stripe/retrieve-payment/${paymentMethodId}`,
      );
      return response.data;
    } catch (error: unknown) {
      return error;
    }
  },
);

const paymentService = {
  chargePayment,
  getSubscriptions,
  getCompanySubscriptions,
};
export default paymentService;
