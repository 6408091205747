import React, { useEffect, useState } from "react";
import CustomModal from "../CustomModal";
import { Typography } from "../../typrography/Typography";
import { ModalProvider } from "styled-react-modal";
import { ScaledButtonWrapper } from "../CustomModal.style";
import * as Yup from "yup";
import { Formik, FormikHelpers, ErrorMessage } from "formik";
import { StyledFormikForm } from "../../tab/ai-model-manager/Upload.style";
import { FormControl } from "../../form-field/form-control/FormControl";
import Input from "../../input/Input";
import deviceConfigService from "../../../services/device/deviceConfig.service";
import { DeviceInfoWrapper } from "../deploy-device-modal/DeployDeviceModal.styled";
import { updateDeviceName } from "../../../store/slices/devices/devicesSlice";
import { useAppDispatch } from "../../../store/hooks";
import {
  ResponseObject,
  SSEResponseObject,
} from "../../../interfaces/response/Response";
import { OpenErrorNotification } from "../../notification/Notification";
interface Props {
  isOpenModel: boolean;
  masterDeviceId: string | undefined;
  deviceId: string | undefined;
  deviceName: string | undefined;
  onClose: () => void;
}

interface FormValues {
  name: string;
}
const validationSchema = Yup.object({
  name: Yup.string().required("Device Name is required"),
});
export default function DeviceNameModal({
  isOpenModel,
  masterDeviceId,
  deviceId,
  deviceName,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const initialValues = {
    name:
      deviceName && !deviceName.toLocaleLowerCase().includes("unnamed device")
        ? deviceName
        : "",
  };
  const [loading, setLoading] = useState(false);

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0.725rem", fontWeight: "700" }}
      >
        Edit Device name
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <ScaledButtonWrapper
        buttonMargin="0.5rem"
        style={{ justifyContent: "end" }}
      >
        <button type="submit" disabled={loading} form="select-device">
          {loading ? "Saving..." : "Save"}
        </button>
      </ScaledButtonWrapper>
    );
  };
  /**
   * Handle form submission
   */
  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    // Update database and device
    setLoading(true);
    await deviceConfigService
      .patchDeviceConfigName(deviceId || "", values.name, dispatch)
      .then((response: ResponseObject<string> | null) => {
        // 1. Update state with value
        // update state with value "header" in deviceSlice
        if (response !== null) {
          if (!response.IsError) {
            dispatch(
              updateDeviceName({
                masterDeviceId: masterDeviceId,
                deviceId: deviceId,
                deviceName: values.name,
              }),
            );
            // 2. Close model
            setLoading(false);
            onClose();
          }
        }
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("API call error:", error);
        setLoading(false);
      });
    actions.setSubmitting(false);
  };
  return (
    <ModalProvider>
      <CustomModal
        isOpen={isOpenModel}
        onClose={onClose}
        header={cardHeader()}
        footer={cardFooter()}
      >
        <DeviceInfoWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            width={"100%"}
          >
            {({
              isSubmitting,
              values,
              handleChange,
              handleBlur,
              submitForm,
            }) => (
              <StyledFormikForm id={"select-device"}>
                <FormControl>
                  <Input
                    name="name"
                    placeHolder="Device Name"
                    label={"Device Name"}
                    width="100%"
                    inputWrapperWidth={"100%"}
                    value={values.name} // Now it will recognize values.name
                    onChange={handleChange}
                  />
                </FormControl>
              </StyledFormikForm>
            )}
          </Formik>
        </DeviceInfoWrapper>
      </CustomModal>
    </ModalProvider>
  );
}
