import React, { useEffect, useState } from "react";
import { SideNavBarContainer } from "./SideNavBar.style";
import SwrmNavLogo from "../../../assets/SWRM_Logo_512x80_Yellow.png";
import {
  FaNetworkWired,
  FaRegImages,
  FaRobot,
  FaUserLock,
} from "react-icons/fa";
import { IoLocationSharp, IoStatsChartSharp } from "react-icons/io5";
import { IoMdRefreshCircle, IoIosNotifications } from "react-icons/io";
import { AiFillBug } from "react-icons/ai";
import { useAppSelector } from "../../../store/hooks";
import {
  selectMiddleSideNavLinks,
  selectBottomSideNavLinks,
  activeNavLink,
} from "./sideNavBarSlice";
import { useAppDispatch } from "../../../store/hooks";
import { IconContext } from "react-icons";
import { NavLink, useLocation } from "react-router-dom";
import { MdLiveHelp } from "react-icons/md";
import ReportBugModal from "../../modals/report-bug-modal/ReportBugModal";
import NotificationModal from "../../modals/notification-modal/NotificationModal";
import { ReactComponent as SwrmWhiteLogo } from "../../../assets/SWRM_Logo_Icon_White.svg";
import { DefaultThemeFontSize } from "../../../app/Theme";
import { awsUtils } from "../../../utils/awsUtils";
import Loader from "../../loader/Loader";
import {
  setLoadCompanyLogo,
  setPresignedCompanyLogoUrl,
} from "../../../store/slices/auth/authSlice";
import { handleLogoImageFallback } from "../../../utils/imageUtils";
/**
 * Adding a comment to test
 * @returns
 */
const SideNavBar: React.FC = () => {
  const middleLinks = useAppSelector(selectMiddleSideNavLinks);
  const bottomLinks = useAppSelector(selectBottomSideNavLinks);
  const auth = useAppSelector((state) => state.persisted.auth);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isReportBugModalOpen, setIsReportBugModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/" || path === "/dashboard") {
      dispatch(activeNavLink(1));
    } else if (path.startsWith("/dashboard")) {
      dispatch(activeNavLink(1));
    } else if (path.startsWith("/devices")) {
      dispatch(activeNavLink(2));
    } else if (path.startsWith("/captures")) {
      dispatch(activeNavLink(3));
    } else if (path.startsWith("/locations")) {
      dispatch(activeNavLink(4));
    } else if (path.startsWith("/permissions")) {
      dispatch(activeNavLink(5));
    } else if (path.startsWith("/ai-model-manager")) {
      dispatch(activeNavLink(6));
    } else if (path.startsWith("/integration")) {
      dispatch(activeNavLink(7));
    } else if (path.startsWith("/wiki")) {
      dispatch(activeNavLink(8));
    } else if (path.startsWith("/help")) {
      dispatch(activeNavLink(9));
    } else if (path.startsWith("/reportABug")) {
      dispatch(activeNavLink(10));
    } else {
      dispatch(activeNavLink(0));
    }
  }, [dispatch, location]);

  const switchIcons = (linkName: string) => {
    switch (linkName) {
      case "Dashboard Stats":
        return <IoStatsChartSharp />;
      case "Device Manager":
        return <FaNetworkWired />;
      case "Captures":
        return <FaRegImages />;
      case "Locations & Groups":
        return <IoLocationSharp />;
      case "Permissions":
        return <FaUserLock />;
      case "Computer Vision Manager":
        return <FaRobot />;
      case "Integration":
        return <IoMdRefreshCircle />;
      case "Notifications":
        return <IoIosNotifications />;
      case "Wiki":
        return (
          <SwrmWhiteLogo
            className="logo"
            style={{
              width: DefaultThemeFontSize.xl,
              height: DefaultThemeFontSize.xl,
            }}
          />
        );
      case "Help":
        return <MdLiveHelp />;
      case "Report a Bug":
        return <AiFillBug />;
    }
  };

  const handleImageURLFetching = async () => {
    auth.companyTheme.Media ?
      await fetchSignedImageURL()
    : dispatch(setLoadCompanyLogo(false));
  };

  const fetchSignedImageURL = async () => {
    if (auth.companyTheme.Media) {
      const signedUrl: string | null = await awsUtils.getSignedImageURL(
        auth.companyTheme.Media.Url,
      );
      dispatch(setPresignedCompanyLogoUrl(signedUrl ?? ""));
      dispatch(setLoadCompanyLogo(false));
    }
  };

  const handleCompanyLogoImageOnError = async () => {
    if (auth.companyTheme.Media) {
      dispatch(setLoadCompanyLogo(true));
      const signedUrl: string | null = await handleLogoImageFallback(
        auth.companyTheme.Media.Url,
      );
      dispatch(setPresignedCompanyLogoUrl(signedUrl ?? ""));
      dispatch(setLoadCompanyLogo(false));
    }
  };

  //Add the SWRM wiki link instead of open report a bug modal as required
  const handleOpenReportBugModal = () => {
    setIsReportBugModalOpen(true);
  };
  const handleCloseReportBugModal = () => {
    setIsReportBugModalOpen(false);
  };
  const handleOpenNotificationModal = () => {
    setIsNotificationModalOpen(true);
  };
  const handleCloseNotificationModal = () => {
    setIsNotificationModalOpen(false);
  };
  const handleOpenSwrmWikiLink = () => {
    const wikiUrl = "https://wiki.swrm.ai/";
    window.open(wikiUrl, "_blank");
  };
  const handleOpenSwrmWikiHelpLink = () => {
    const wikiUrl = "https://wiki.swrm.ai/en/SWRM-Dashboard/FAQ";
    // Open the link in a new tab
    window.open(wikiUrl, "_blank");
  };
  const handleOpenSwrmWikiReportABugLink = () => {
    const wikiUrl =
      "https://wiki.swrm.ai/en/SWRM-Dashboard/ReportaBugorContactSupport";
    window.open(wikiUrl, "_blank");
  };

  useEffect(() => {
    auth.loadCompanyLogo && handleImageURLFetching();
  }, [auth.companyTheme.Media]);

  return (
    <>
      <SideNavBarContainer>
        {auth.loadCompanyLogo ?
          <Loader loadingText="" />
        : <img
            src={auth.presignedCompanyLogoUrl || SwrmNavLogo}
            width="auto"
            height="15%"
            onError={handleCompanyLogoImageOnError}
            alt="SWRM Logo"
            style={{
              objectFit: "contain",
            }}
          />
        }

        <div>
          <nav className="side-nav-center">
            <ul>
              {middleLinks.map((link) => (
                <li
                  key={link.id}
                  className={link.isActive ? "active-link" : ""}
                >
                  <NavLink
                    to={link.href}
                    onClick={() => dispatch(activeNavLink(link.id))}
                  >
                    <IconContext.Provider
                      value={{ size: DefaultThemeFontSize.xl }}
                    >
                      {switchIcons(link.name)}
                    </IconContext.Provider>
                    <span>{link.name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div>
          <nav className="side-nav-bottom">
            <ul>
              {bottomLinks.map((link) => (
                <li
                  key={link.id}
                  className={link.isActive ? "active-link" : ""}
                  onClick={() => dispatch(activeNavLink(link.id))}
                >
                  {
                    link.name === "Notifications" ?
                      <button onClick={handleOpenNotificationModal}>
                        <IconContext.Provider
                          value={{ size: DefaultThemeFontSize.xl }}
                        >
                          {switchIcons(link.name)}
                        </IconContext.Provider>
                        <span>{link.name}</span>
                      </button>
                    : link.name === "Wiki" ?
                      //Add the SWRM wiki link instead of open report a bug modal as required
                      <button onClick={handleOpenSwrmWikiLink}>
                        <IconContext.Provider
                          value={{ size: DefaultThemeFontSize.xl }}
                        >
                          {switchIcons(link.name)}
                        </IconContext.Provider>
                        <span>{link.name}</span>
                      </button>
                    : link.name === "Report a Bug" ?
                      //Add the SWRM wiki link instead of open report a bug modal as required
                      <button onClick={handleOpenSwrmWikiReportABugLink}>
                        <IconContext.Provider
                          value={{ size: DefaultThemeFontSize.xl }}
                        >
                          {switchIcons(link.name)}
                        </IconContext.Provider>
                        <span>{link.name}</span>
                      </button>
                      // Replace the link of "Help" side nav bar to wiki as required
                    : <button onClick={handleOpenSwrmWikiHelpLink}>
                        <IconContext.Provider
                          value={{ size: DefaultThemeFontSize.xl }}
                        >
                          {switchIcons(link.name)}
                        </IconContext.Provider>
                        <span>{link.name}</span>
                      </button>


                    // <a href={link.href}>
                    //   <IconContext.Provider value={{ size: DefaultThemeFontSize.xl }}>
                    //     {switchIcons(link.name)}
                    //   </IconContext.Provider>
                    //   <span>{link.name}</span>
                    // </a>
                  }
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </SideNavBarContainer>
      <ReportBugModal
        isOpen={isReportBugModalOpen}
        onClose={handleCloseReportBugModal}
        title={"Bug Report"}
        dropdownLabel={"Select Location Type"}
        buttonText={"Submit"}
        description={""}
        editorLabel={"Describe what happened"}
        buttonPadding={"10px"}
      />
      <NotificationModal
        isOpen={isNotificationModalOpen}
        onClose={handleCloseNotificationModal}
        title={"My Notifications"}
        dropdownLabel={"Select Location Type"}
        buttonText={"Submit"}
        description={""}
        editorLabel={"Describe what happened"}
        buttonPadding={"10px"}
      />
    </>
  );
};
export default React.memo(SideNavBar);
